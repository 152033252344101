import { Link } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import api from '../api/api';
import { KioskTimeConfig } from './kioskTimeConfig';
import QRCode from 'react-qr-code';
import Modal from 'react-bootstrap/Modal';
import stringHelpers from '../../utilities/stringHelpers';

export default function KioskDetails(props) {
    const [site, setSite] = useState(null);
    const [kiosk, setKiosk] = useState(null);
    const [kioskEditMode, setKioskEditMode] = useState(false);
    const [kioskName, setKioskName] = useState(null);
    const [webKioskEnabled, setWebKioskEnabled] = useState(null);
    const [kioskAppEnabled, setKioskAppEnabled] = useState(null);
    const [timeSpanRows, setTimeSpanRows] = useState([]);
    const [kioskUrl, setKioskUrl] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const kioskBaseUrl = `https://kiosk.advancedparking.tech/${ props.organisationId }/sites/${ props.match.params.siteId }`
    const svgRef = useRef();
    const fetchKiosk = () => {
        api.getSite(
            data => setSite(data),
            () => { },
            props.match.params.siteId,
            props.organisationId,
            props.token
        )
        api.getKioskById(
            data => {
                setKiosk(data);
                setKioskName(data.name)
                setKioskAppEnabled(data.isAppEnabled);
                setWebKioskEnabled(data.isWebEnabled);
                if (data.timeSpans){
                    setTimeSpanRows(data.timeSpans)
                }
                if (data.shortCode) {
                    setKioskUrl(`${ kioskBaseUrl }/${ data.shortCode }`)
                } else {
                    setKioskUrl(kioskBaseUrl)
                }
            },
            () => { },
            props.organisationId,
            props.match.params.siteId,
            props.match.params.kioskId,
            props.token
        )
    }

    const regenerateKioskShortCode = () => {
        api.regeneateKioskShortCode(
            props.organisationId, props.match.params.siteId, props.match.params.kioskId,
            data => {
                if (data.shortCode) {
                    setKioskUrl(`${ kioskBaseUrl }/${ data.shortCode }`)
                } else {
                    setKioskUrl(kioskBaseUrl)
                }
                setShowModal(false)
            },
            error => console.log(error),
            props.token
        )
    }

    useEffect(() => fetchKiosk(), [])

    const handleKioskConfigurationUpdate = () => {
        api.updateKioskConfiguration(
            props.organisationId, props.match.params.siteId, kioskName, props.match.params.kioskId,
            kioskAppEnabled, webKioskEnabled, timeSpanRows,
            () => fetchKiosk(),
            () => { },
            props.token
        )
    }

    const updateTimeSpanRow = (id, value, mode) => setTimeSpanRows(timeSpanRows.toSpliced(id, 1, { type: mode, duration: value }))

    const handleQRDownload = () => {
        if (svgRef.current) {
            const serializer = new XMLSerializer();
            const blob = new Blob([serializer.serializeToString(svgRef.current)], {type: "image/svg+xml"});
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'qr-code.svg';
            link.click();
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    {
                        site && kiosk &&
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">{ kiosk.name }</h5>
                                    </div>
                                    <Link to={ `/organisation/${ props.organisationId }/client/${ site.clientId }/site/${ props.match.params.siteId }` } className="btn btn-falcon-default btn-sm mr-3 py-1" type="button"><i className="fas fa-reply"></i> Back</Link>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        kiosk &&
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Details</h5>
                                    </div>
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-primary btn-sm ml-2" onClick={ () => setKioskEditMode(!kioskEditMode) }>
                                            <i className="fas fa-edit"></i> Edit</button>
                                    </div>
                                </div>
                            </div>


                            <div className="card-body bg-light border-top details-container">
                                <>
                                    {
                                        kioskEditMode
                                            ? <div className="row">
                                                <div className="col-md-3 col-12">
                                                    <p className="font-weight-semi-bold mb-3">Kiosk Name<span className="req"></span></p>
                                                </div>
                                                <div className="col-md-6 col-12 px-0">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={ kioskName } onChange={ (e) => setKioskName(e.target.value) } />
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="row">
                                                <div className="col-md-3 col-12">
                                                    <p className="font-weight-semi-bold mb-3">Kiosk Name</p>
                                                </div>
                                                <p>{ kiosk.name }</p>
                                            </div>
                                    }
                                </>

                                <>
                                    {
                                        kioskEditMode
                                            ? <div className="row">
                                                <div className="col-md-3 col-12">
                                                    <p className="font-weight-semi-bold mb-3">Web Kiosk Enabled</p>
                                                </div>
                                                <div className="col-md-6 col-12 custom-control custom-switch">
                                                    <input
                                                        disabled={ !kioskEditMode }
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        id="web-kiosk-enabled"
                                                        checked={ webKioskEnabled }
                                                        onChange={ () => setWebKioskEnabled(!webKioskEnabled) } />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="web-kiosk-enabled"
                                                    ></label>
                                                </div>
                                            </div>
                                            : <div className="row mb-2">
                                                <div className="col-md-3 col-12">
                                                    <p className="font-weight-semi-bold mb-3">Web Kiosk Enabled</p>
                                                </div>
                                                {
                                                    webKioskEnabled && kioskUrl
                                                        ? <div className="col-md-9 col-12 pl-0">
                                                            <i class="fas fa-check text-success mr-2 my-2"></i>
                                                            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "0.35rem"}}>
                                                                <a target="_blank" href={ kioskUrl } className="m-0">
                                                                    <h6 className='m-0'>{ kioskUrl }</h6>
                                                                </a>
                                                                <button className="btn mx-3 p-0" onClick={ () => navigator.clipboard.writeText(kioskUrl) }>
                                                                    <i className="fas fa-copy" />
                                                                </button>
                                                                <button className="btn btn-falcon-default btn-sm mr-3 py-1" onClick={ () => setShowModal(true) }>
                                                                    <i className="fas fa-redo mr-2" />
                                                                    Regenerate URL and QR Code
                                                                </button>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div style={ { width: "100px", height: "100px", marginTop: "8px" } }>
                                                                    <QRCode ref={svgRef} style={ { height: "auto", maxWidth: "100%", width: "100%" } } value={ kioskUrl } />
                                                                </div>
                                                                <button onClick={() => handleQRDownload()} className="btn btn-falcon-default btn-sm ml-2 align-self-end">
                                                                    <i className="fas fa-download mr-2" />
                                                                    Download QR Code
                                                                </button>
                                                            </div>
                                                        </div>
                                                        : <i class="fas fa-times text-danger mt-1"></i>
                                                }
                                            </div>
                                    }
                                </>

                                <>
                                    {
                                        kioskEditMode
                                            ? <div className="row">
                                                <div className="col-md-3 col-12">
                                                    <p className="font-weight-semi-bold mb-3">Kiosk App Enabled</p>
                                                </div>
                                                <div className="col-md-6 col-12 custom-control custom-switch ">
                                                    <input
                                                        disabled={ !kioskEditMode }
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        id="kiosk-app-enabled"
                                                        checked={ kioskAppEnabled }
                                                        onChange={ () => setKioskAppEnabled(!kioskAppEnabled) } />
                                                    <label className="custom-control-label" htmlFor="kiosk-app-enabled"></label>
                                                </div>
                                            </div>
                                            : <div className="row">
                                                <div className="col-md-3 col-12">
                                                    <p className="font-weight-semi-bold mb-3">Kiosk App Enabled</p>
                                                </div>
                                                {
                                                    kioskAppEnabled
                                                        ? <i class="fas fa-check text-success mt-1"></i>
                                                        : <i class="fas fa-times text-danger mt-1"></i>
                                                }
                                            </div>
                                    }
                                </>

                                <>
                                    {
                                        timeSpanRows &&
                                        <div className="row">
                                            <div className="col-md-3 col-12">
                                                <p className="font-weight-semi-bold mb-3">Time Spans{kioskEditMode && <span className="req"></span>}</p>
                                            </div>
                                            <div className="col-md-2 col-12 pl-0">
                                                <KioskTimeConfig
                                                    updateList={ newList => setTimeSpanRows(newList) }
                                                    handleAddRow={ (value, mode) => {
                                                        const payloadValue = mode === 3 ? null : value;
                                                        setTimeSpanRows(prevState => [...prevState, { type: parseInt(mode), duration: payloadValue }])
                                                    } }
                                                    handleUpdateRow={ (id, value, mode) => updateTimeSpanRow(id, value, mode) }
                                                    handleDeleteRow={ id => setTimeSpanRows(timeSpanRows.toSpliced(id, 1)) }
                                                    data={ timeSpanRows }
                                                    editMode={ kioskEditMode }
                                                />
                                            </div>
                                        </div>
                                    }
                                </>

                                <>
                                    { kioskEditMode && (
                                        <div className="card-footer border-top ">
                                            <div className="row client-edit-buttons">
                                                <div className="col-6 col-sm-auto ml-auto pl-0">
                                                    <div id="dashboard-actions">
                                                        <button
                                                            onClick={ () => {
                                                                setKioskEditMode(false);
                                                                fetchKiosk();
                                                            } }
                                                            className="btn btn-falcon-default btn-sm">
                                                            <i className="fas fa-times"></i> Cancel
                                                        </button>
                                                        <button
                                                            type="button"
                                                            disabled={timeSpanRows.length === 0 || stringHelpers.isNullOrEmpty(kioskName)}
                                                            className="btn btn-success btn-sm ml-2"
                                                            onClick={ () => {
                                                                handleKioskConfigurationUpdate();
                                                                setKioskEditMode(false)
                                                            } }>
                                                            <i className="fas fa-check"></i> Confirm
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) }
                                </>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <Modal show={ showModal } onHide={ () => { setShowModal(false) } }>
                <Modal.Header>
                    <Modal.Title>
                        Warning
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 ">
                            <p className="font-weight-semi-bold  mt-1 mb-2">If you continue the previous QR Code for this kiosk will no longer work. Are you sure?</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-sm btn-danger mt-1"
                        onClick={ () => { setShowModal(false) } }
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-sm btn-success mt-1"
                        onClick={ () => regenerateKioskShortCode() }
                    >
                        Confirm
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}