import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import StringHelpers from '../../utilities/stringHelpers';
import Loader from '../shared/loader';
import UploadFile from '../shared/file-uploader';
import ConditionDialog from './_conditionDialog';
import ItemDialog from './_itemDialog';

var cloneDeep = require('lodash.clonedeep');
var hash = require('object-hash');

function DocumentTemplate(props) {
    const [documentId, setDocumentId] = useState(props.match.params.documentId);
    const [documentTemplate, setDocumentTemplate] = useState(null);
    const [attachmentId, setAttachmentId] = useState(null);

    const [errorMessage, setErrorMessage] = useState("");
    const [canSave, setCanSave] = useState(false);

    const [isView, setIsView] = useState(false);
    const [loading, setLoading] = useState(false);

    const [docName, setDocName] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [showGridLines, setShowGridLines] = useState(false);
    const [showOutOfBounds, setShowOutOfBounds] = useState(false);
    const [items, setItems] = useState(null);

    const [conditions, setConditions] = useState([]);

    const [additionalCharge, setAdditionalCharge] = useState(0);
    const [additionalChargeType, setAdditionalChargeType] = useState(0);
    const [debtRecoveryCharge, setDebtRecoveryCharge] = useState(0);
    const [debtRecoveryChargeType, setDebtRecoveryChargeType] = useState(0);
    const [completeStatus, setCompleteStatus] = useState(null);
    const [resetCharges, setResetCharges] = useState(false);
    const [resetDateIssued, setResetDateIssued] = useState(false);
    const [allowManualSend, setAllowManualSend] = useState(false);
    const [processOnPublicHoildays, setProcessOnPublicHoildays] = useState(false);
    const [processOnMondays, setProcessOnMondays] = useState(false);
    const [processOnTuesdays, setProcessOnTuesdays] = useState(false);
    const [processOnWednesdays, setProcessOnWednesdays] = useState(false);
    const [processOnThursdays, setProcessOnThursdays] = useState(false);
    const [processOnFridays, setProcessOnFridays] = useState(false);
    const [processOnSaturdays, setProcessOnSaturdays] = useState(false);
    const [processOnSundays, setProcessOnSundays] = useState(false);
    const [processingTime, setProcessingTime] = useState(null);

    const [allContraventions, setAllContraventions] = useState(null);
    const [selectedContraventions, setSelectedContraventions] = useState([]);
    const [chargeTypes, setChargeTypes] = useState(null);

    const [editingCondition, setEditingCondition] = useState(null);
    const [showConditionDialog, setShowConditionDialog] = useState(false);
    
    const [editingItem, setEditingItem] = useState(null);
    const [showItemDialog, setShowItemDialog] = useState(false);

    const history = useHistory();

    const addCondition = function() {
        setEditingCondition(null);
        setShowConditionDialog(true);
    }

    const editCondition = function(condition) {
        setEditingCondition(condition);
        setShowConditionDialog(true);
    }    

    const cancelEditingCondition = function() {        
        setEditingCondition(null);
        setShowConditionDialog(false);
    }

    const deleteCondition = function (condition) {
        Api.deleteTemplateCondition(
            () => {

                let conditionsClone = cloneDeep(conditions);
                let filtered = conditionsClone.filter(function (x) {
                    return x.id !== condition.id;
                });

                setConditions(filtered);
            },
            (error) => console.log(error),
            condition.id, documentId,
            props.organisationId,
            props.token
        )
    }

    const saveCondition = function (condition, argument) {
        if (editingCondition === null) {
            Api.addTemplateCondition(
                (data) => {
                    setConditions(conditions.concat([ data ]));        
                },
                (error) => console.log(error),
                documentId, Number(condition), argument,
                props.organisationId, props.token
            );
        } else {
            Api.editTemplateCondition(
                (data) => {
                    var conditionIndex = conditions.findIndex(({ id }) => id === editingCondition.id);

                    if (conditionIndex >= 0) {
                        let conditionsClone = cloneDeep(conditions);
                        conditionsClone.splice(conditionIndex, 1, data);
                        setConditions(conditionsClone);
                    }
                    
                    setEditingCondition(null);      
                },
                (error) => console.log(error),
                editingCondition.id, documentId, Number(condition), argument,
                props.organisationId, props.token
            );
        }

        setShowConditionDialog(false);
    };

    const addItem = function() {
        setEditingItem(null);
        setShowItemDialog(true);
    };

    const editItem = function(item) {
        setEditingItem(item);
        setShowItemDialog(true);
    };  

    const cancelEditingItem = function() {        
        setEditingItem(null);
        setShowItemDialog(false);
    };

    const deleteItem = function (index) {

        let itemsClone = cloneDeep(items);
        itemsClone.splice(index, 1);
        setItems(itemsClone);
    }

    const saveItem = function(item) {
        if (editingItem === null) {
            setItems(items.concat([ item ]));        
        } else {
    
            var itemIndex = items.indexOf(editingItem);

            if (itemIndex >= 0) {
                let itemsClone = cloneDeep(items);
                itemsClone.splice(itemIndex, 1, item);
                setItems(itemsClone);
            }
            
            setEditingItem(null);   
        }

        setShowItemDialog(false);
    };

    const getAllContraventions = function () {
        Api.getContraventions(
            (data) => setAllContraventions(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    const getDocument = function () {
        setErrorMessage("");

        Api.getLetterTemplate(
            (data) => setDocumentTemplate(data),
            (error) => setErrorMessage("Template fetch failed, please try again."),
            documentId, props.organisationId, props.token
        )
    };

    const preview = function () {
        if (attachmentId !== null && attachmentId !== "") {
            setErrorMessage("");

            setLoading(true);
            Api.previewLetterTemplate(
                (data) => {
                    setLoading(false);
                    var blob = new window.Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(blob);
                    var iframe = '<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;"></iframe>';
                    if (document.getElementById('preview-pdf') !== null) {
                        document.getElementById('preview-pdf').innerHTML = iframe;
                    }
                },
                (error) => {
                    //don't stop loading if abort error
                    if (error.toString().indexOf('abort') === -1) {
                        setLoading(false);
                    }
                    if (document.getElementById('preview-pdf') !== null) {
                        document.getElementById('preview-pdf').innerHTML = '<p>Failed to load preview.</p>';
                    };
                },
                attachmentId, items, showOutOfBounds, showGridLines,
                props.organisationId, props.token
            );
        }
    };

    const deleteTemplate = function () {
        setErrorMessage("");

        Api.deleteLetterTemplate(
            () => history.push(docDashUrl),
            () => setErrorMessage("Delete failed, please try again."),
            documentId, props.organisationId, props.token
        )
    };

    const saveTemplate = function () {
        setErrorMessage("");

        if (selectedContraventions.length === 0) {
            setErrorMessage("You must select at least one contravention.")
        } else {
            if (StringHelpers.isNullOrEmpty(documentId)) {
                Api.addLetterTemplate(
                    () => history.push(docDashUrl),
                    () => setErrorMessage("Save failed, please try again."),
                    additionalChargeType, additionalCharge, debtRecoveryCharge, debtRecoveryChargeType,
                    completeStatus, resetCharges, resetDateIssued, processOnMondays, processOnTuesdays,
                    processOnWednesdays, processOnThursdays, processOnFridays, processOnSaturdays,
                    processOnSundays, processOnPublicHoildays, processingTime, selectedContraventions, allowManualSend,
                    attachmentId, docName, items, isActive, props.organisationId, props.token
                )
            } else {
                Api.editLetterTemplate(
                    () => {
                    },
                    () => setErrorMessage("Save failed, please try again."),
                    additionalChargeType, additionalCharge, debtRecoveryCharge, debtRecoveryChargeType,
                    completeStatus, resetCharges, resetDateIssued, processOnMondays, processOnTuesdays,
                    processOnWednesdays, processOnThursdays, processOnFridays, processOnSaturdays,
                    processOnSundays, processOnPublicHoildays, processingTime, selectedContraventions, allowManualSend,
                    documentId, attachmentId, docName, items, isActive, props.organisationId, props.token
                )
            }
        }
    };

    const setAllChargeTypes = function () {
        var temp = [];
        for (let index = 0; index => 0; index++) {
            if (Enums.ChargeType[index] !== undefined) {
                temp.push(index);
            } else {
                break;
            }
        }
        setChargeTypes(temp);
    };

    const toggleSelectedContravention = function (contraventionId) {
        let selectedContraventionsClone = cloneDeep(selectedContraventions);
        let itemIndex = selectedContraventionsClone.findIndex(x => x.contraventionId === contraventionId);
        
        if (itemIndex >= 0) {
            selectedContraventionsClone.splice(itemIndex, 1);
        } else {
            selectedContraventionsClone.push( { contraventionId });
        }

        setSelectedContraventions(selectedContraventionsClone);
    };

    useEffect(() => {
        setAdditionalCharge(0)
    },[additionalChargeType]);
    
    useEffect(() => {
        setDebtRecoveryCharge(0)
    },[debtRecoveryChargeType]);

    useEffect(() => {
        if (documentTemplate !== null) {
            setItems(documentTemplate.items);
            setAttachmentId(documentTemplate.attachmentId);
            setDocName(documentTemplate.name);
            setIsActive(documentTemplate.isActive);
            setIsView(true);
            setConditions(documentTemplate.conditions)

            setAdditionalCharge(documentTemplate.additionalCharge)
            
            if(documentTemplate.additionalChargeType !== null) {
                setAdditionalChargeType(documentTemplate.additionalChargeType)
            }

            setDebtRecoveryCharge(documentTemplate.debtRecoveryCharge)

            if(documentTemplate.debtRecoverChargeType !== null) {
                setDebtRecoveryChargeType(documentTemplate.debtRecoverChargeType)
            }

            setCompleteStatus(documentTemplate.completeStatus)
            setResetCharges(documentTemplate.resetCharges)
            setResetDateIssued(documentTemplate.resetDateIssued)
            setProcessOnPublicHoildays(documentTemplate.processOnPublicHoildays)
            setProcessOnMondays(documentTemplate.processOnMondays)
            setProcessOnTuesdays(documentTemplate.processOnTuesdays)
            setProcessOnWednesdays(documentTemplate.processOnWednesdays)
            setProcessOnThursdays(documentTemplate.processOnThursdays)
            setProcessOnFridays(documentTemplate.processOnFridays)
            setProcessOnSaturdays(documentTemplate.processOnSaturdays)
            setProcessOnSundays(documentTemplate.processOnSundays)
            setProcessingTime(documentTemplate.processingTime)

            setSelectedContraventions(documentTemplate.contraventions);
            setAllowManualSend(documentTemplate.allowManualSend)
        }
    }, [documentTemplate]);

    useEffect(() => {
        preview();
    }, [showOutOfBounds, showGridLines]);

    useEffect(() => {
        preview()
    }, [items, attachmentId]);

    useEffect(() => {
        //should also contian contravention
        if(docName !== null && docName !== "" && completeStatus !== null && attachmentId !== null){
            setCanSave(true);
        } else {
            setCanSave(false);
        }
    }, [docName, completeStatus, attachmentId]);

    useEffect(() => {
        if (documentId !== null && documentId !== undefined) {
            getDocument();
        } else {
            setIsView(true);
            preview()
        }
        getAllContraventions();
        setAllChargeTypes();
    }, []);

    var docDashUrl = '/organisation/' + props.organisationId + '/letter-templates';

    return (
        <>
            {!isView && documentTemplate === null && <><Loader /></>}
            {isView && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-7">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto d-flex align-items-center pr-0">
                                            <h5 className="mb-0">{documentTemplate === null && <>New Letter Template</>}{documentTemplate !== null && <>{docName}</>}</h5>
                                        </div>
                                        <div className="col-auto ml-auto text-right pl-0 filter-buttons-wrapper">
                                            <Link className="btn btn-falcon-default btn-sm mr-1 ml-1 nowrap" to={docDashUrl}><i className="fas fa-reply"></i> Back</Link>
                                            {StringHelpers.isNullOrEmpty(attachmentId) && 
                                                <button className="btn btn-sm btn-falcon-default  mr-1 ml-1 nowrap" data-toggle="modal" data-target="#add-new-evidence">Add Letter Template </button>
                                            }
                                            {!StringHelpers.isNullOrEmpty(attachmentId) &&
                                                <button className="btn btn-sm btn-falcon-default  mr-1 ml-1 nowrap" data-toggle="modal" data-target="#add-new-evidence">Change Template</button>
                                            }
                                            {!StringHelpers.isNullOrEmpty(documentId) &&
                                                <button className="btn btn-danger btn-sm mr-1 ml-1 nowrap" onClick={() => deleteTemplate()}>Delete</button>
                                            }
                                            <button className="btn btn-success btn-sm mr-1 ml-1 nowrap" disabled={!canSave} onClick={() => saveTemplate()}>Save</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top">
        
                                    {errorMessage !== "" && 
                                        <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mb-2">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Name</label>
                                        <div class="col-sm-8">
                                            <input className="form-control form-control-sm" type="text" value={docName} onChange={(e) => setDocName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Status</label>
                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="active" checked={isActive} onChange={(e) => { console.log(e.target.checked); setIsActive(e.target.checked)}} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="active">
                                                {isActive && <>Active</>}{!isActive && <>Deactivated</>}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Show Out of Bounds</label>
                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="bounds" checked={showOutOfBounds} onChange={() => setShowOutOfBounds(!showOutOfBounds)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="bounds">
                                                {showOutOfBounds && <>Show</>}{!showOutOfBounds && <>Hide</>}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row mb-2">                                        
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Show Grid Lines</label>
                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="grid" checked={showGridLines} onChange={() => setShowGridLines(!showGridLines)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="grid">
                                            {showGridLines && <>Show</>}{!showGridLines && <>Hide</>}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row mb-2">                                        
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Reset Date Issued</label> 
                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="reset-date" checked={resetDateIssued} onChange={() => setResetDateIssued(!resetDateIssued)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="reset-date">
                                            {resetDateIssued && <>Reset</>}{!resetDateIssued && <>Retain</>}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row mb-2">                                        
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Allow Manual Send</label> 
                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="manual-send" checked={allowManualSend} onChange={() => setAllowManualSend(!allowManualSend)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="manual-send">
                                            {allowManualSend ? <>Yes</> : <>No</>}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row mb-2">                          
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Complete Status</label> 
                                        <div className="col-sm-8">
                
                                            <div id="select-status">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 edit-dropdown" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{completeStatus !== null && <>{Enums.BreachStatus[completeStatus]}</>}{completeStatus === null && <>Select Status</>}</button>
                                                <div className="dropdown-menu py-0  edit-dropdown" aria-labelledby="select-status">
                                                    <div className="bg-white py-3 rounded-soft tall-dropdown">
                                                        {Object.keys(Enums.BreachStatus).map((item) => {
                                                            return (
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCompleteStatus(Number(item))}>{Enums.BreachStatus[item]}</button>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-2">       
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Contraventions</label> 
                                        <div className="col-sm-8">
                                            {allContraventions !== null && allContraventions.map((item, i) => {
                                                let isSelected = selectedContraventions.find(x => x.contraventionId === item.id);
                                                return <div className="custom-control custom-checkbox" key={hash( { item, isSelected })} index={i}>
                                                    <input className="custom-control-input" type="checkbox" checked={isSelected} id={item.id} onChange={() => toggleSelectedContravention(item.id) } />
                                                    <label className="custom-control-label col-form-label col-form-label-sm" htmlFor={item.id}>{item.name} ({Enums.ContraventionTypes[item.contraventionType]})</label>
                                                </div>
                                            }
                                            )}
                                        </div>
                                    </div>
                  
                                    <h6 class="fw-semi-bold ls mt-5 mb-3 text-uppercase">Charges</h6>

                                    <div className="row mb-3">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Debt Recovery Charge</label>                               
                                        <div className="col-sm-8">
                                            <div className="input-group input-group-sm">
                                                <div className="input-group-prepend">
                                                    <button className="btn btn-falcon-default btn-sm dropdown-toggle edit-dropdown ws-normal" id="select-debt-charge" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{Enums.ChargeType[debtRecoveryChargeType]}</button>
                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-debt-charge">
                                                        <div className="bg-white py-3 rounded-soft">
                                                            {chargeTypes !== null && chargeTypes.map((item) => {
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setDebtRecoveryChargeType(item)}>{Enums.ChargeType[item]}</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                {debtRecoveryChargeType === 0 &&
                                                    <input className="form-control mb-0" type="number" min={0} value={debtRecoveryCharge} onChange={(e) => setDebtRecoveryCharge(e.target.value)} />
                                                }
                                                {debtRecoveryChargeType === 1 &&
                                                    <input className="form-control mb-0" type="number" min={0} max={100} value={debtRecoveryCharge} onChange={(e) => setDebtRecoveryCharge(e.target.value)} />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Additional Charge</label>                               
                                        <div className="col-sm-8">
                                            <div className="input-group input-group-sm">
                                                <div className="input-group-prepend">
                                                    <button className="btn btn-falcon-default btn-sm dropdown-toggle edit-dropdown ws-normal" id="select-debt-charge" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{Enums.ChargeType[additionalChargeType]}</button>
                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-debt-charge">
                                                        <div className="bg-white py-3 rounded-soft">
                                                            {chargeTypes !== null && chargeTypes.map((item) => {
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setAdditionalChargeType(item)}>{Enums.ChargeType[item]}</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                {additionalChargeType === 0 &&
                                                    <input className="form-control mb-0" type="number" min={0} value={additionalCharge} onChange={(e) => setAdditionalCharge(e.target.value)} />
                                                }
                                                {additionalChargeType === 1 &&
                                                    <input className="form-control mb-0" type="number" max={100} min={0} value={additionalCharge} onChange={(e) => setAdditionalCharge(e.target.value)} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div className="row mb-3">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Reset Charges</label>                               

                                        <div className="col-md-8 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="reset-charges" checked={resetCharges} onChange={() => setResetCharges(!resetCharges)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="reset-charges"></label>
                                        </div>
                                    </div>   
                                </div>
                                <div className="modal fade save-dialog success-dialog" id="add-new-evidence" tabIndex="-1" role="dialog">
                                    <UploadFile onSuccess={(file) => setAttachmentId(file.id)} type="Attachment" organisationId={props.organisationId} />
                                </div>                            
                            </div>

                            {!StringHelpers.isNullOrEmpty(documentId) &&
                                <>
                                    <div className="card mb-3">
                                        <div className="card-header">
                                            <div className="row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center pr-0">
                                                    <h5 className="mb-0">Template Items</h5>
                                                </div>
                                                <div className="col-auto ml-auto text-right pl-0 filter-buttons-wrapper">            
                                                    <button className="btn btn-falcon-default btn-sm" onClick={addItem}><i class="fas fa-plus"></i> New</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body p-0'>
                                            <table className="table table-sm table-striped fs--1 mb-0 ">  
                                                <thead className="bg-200 text-900">
                                                    <tr>
                                                        <th className='align-middle'>Name</th>
                                                        <th className='text-center align-middle'>X</th>
                                                        <th className='text-center align-middle'>Y</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='list'>
                                                
                                                    {items !== null && items.map((item, i) =>        
                                                        <tr id={item.id} key={hash(item)} index={i}>
                                                            <td className='align-middle'>
                                                                <a className="btn btn-link btn-sm p-0" onClick={() => editItem(item)}>
                                                                    {item.name}
                                                                </a>    
                                                            </td>
                                                            <td className='text-center align-middle'>{item.x}</td>
                                                            <td className='text-center align-middle'>{item.y}</td>
                                                            <td className="py-2 align-middle white-space-nowrap text-end">
                                                                <button className="btn btn-falcon-default btn-link btn-sm py-2 dropdown-toggle dropdown-caret-none mr-3" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="fas fa-ellipsis-h"></i>
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu-right ml-3" x-placement="bottom-start">
                                                                    <a className="dropdown-item" onClick={() => editItem(item)}>Edit</a>
                                                                    <a className="dropdown-item text-danger" onClick={() => deleteItem(i)}>Delete</a>
                                                                </div>                                                                                    
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {showItemDialog && 
                                        <ItemDialog item={editingItem} onCancel={cancelEditingItem} onSave={saveItem} token={props.token} />
                                    }

                                    <div className="card mb-3">
                                        <div className="card-header">
                                            <div className="row align-items-center justify-content-between">
                                                <div className="col-auto d-flex align-items-center pr-0">
                                                    <h5 className="mb-0">Conditions</h5>
                                                </div>
                                                <div className="col-auto ml-auto text-right pl-0 filter-buttons-wrapper">            
                                                    <button className="btn btn-falcon-default btn-sm" onClick={addCondition}><i class="fas fa-plus"></i> New</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card-body p-0'>
                                            <table className="table table-sm table-striped fs--1 mb-0 ">  
                                                <tbody className='list'>
                                                    {conditions !== null && conditions.map((condition, i) =>        
                                                        <tr id={condition.id} key={hash(condition)} index={i}>
                                                            <td className='align-middle'>
                                                                <a className="btn btn-link btn-sm p-0" onClick={() => editCondition(condition)}>
                                                                    {condition.displayText}
                                                                </a>
                                                            </td>
                                                            <td className="py-2 align-middle white-space-nowrap text-end">
                                                                <button className="btn btn-falcon-default btn-link btn-sm py-2 dropdown-toggle dropdown-caret-none mr-3" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                    <i className="fas fa-ellipsis-h"></i>
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu-right ml-3" x-placement="bottom-start">
                                                                    <a className="dropdown-item" onClick={() => editCondition(condition)}>Edit</a>
                                                                    <a className="dropdown-item text-danger" onClick={() => deleteCondition(condition)}>Delete</a>
                                                                </div>                                                                                    
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {showConditionDialog && 
                                        <ConditionDialog condition={editingCondition} onCancel={cancelEditingCondition} onSave={saveCondition} token={props.token} />
                                    }
                                </>
                            }       

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto d-flex align-items-center pr-0">
                                            <h5 className="mb-0">Processing</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top">
                                    <div className="row mb-3">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Mondays</label>
           
                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="monday" checked={processOnMondays} onChange={() => setProcessOnMondays(!processOnMondays)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="monday"></label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Tuesdays</label>    
                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="tuesday" checked={processOnTuesdays} onChange={() => setProcessOnTuesdays(!processOnTuesdays)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="tuesday"></label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Wednesdays</label> 
                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="wednesday" checked={processOnWednesdays} onChange={() => setProcessOnWednesdays(!processOnWednesdays)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="wednesday"></label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">                                        
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Thursdays</label>
                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="thursday" checked={processOnThursdays} onChange={() => setProcessOnThursdays(!processOnThursdays)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="thursday"></label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">                                        
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Fridays</label>
                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="friday" checked={processOnFridays} onChange={() => setProcessOnFridays(!processOnFridays)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="friday"></label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">                                        
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Saturdays</label>                              

                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="saturday" checked={processOnSaturdays} onChange={() => setProcessOnSaturdays(!processOnSaturdays)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="saturday"></label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Sundays</label>                               

                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="sunday" checked={processOnSundays} onChange={() => setProcessOnSundays(!processOnSundays)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="sunday"></label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Public Holidays</label>  
                                        <div className="col-sm-8 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="holiday" checked={processOnPublicHoildays} onChange={() => setProcessOnPublicHoildays(!processOnPublicHoildays)} />
                                            <label className="custom-control-label col-form-label col-form-label-sm ml-3" htmlFor="holiday"></label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Processing Time</label>  
                         
                                        <div className="col-sm-8">
                                            <div className="input-group input-group-sm">
                                                <input className="form-control mb-1" type="time" value={processingTime} onChange={(e) => setProcessingTime(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-5'>
                            {!loading && !StringHelpers.isNullOrEmpty(attachmentId) &&
                                <div id="preview-pdf" className="preview-pdf-container"></div>
                            }
                            {loading && 
                                <div className="tiny-loader">
                                    <Loader />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default DocumentTemplate;