import { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import api from '../api/api';
import { KioskTimeConfig } from './kioskTimeConfig';
import stringHelpers from "../../utilities/stringHelpers";

export default function AddKiosk(props) {
    const [site, setSite] = useState(null);
    const [kioskName, setKioskName] = useState(null);
    const [webKioskEnabled, setWebKioskEnabled] = useState(false);
    const [kioskAppEnabled, setKioskAppEnabled] = useState(false);
    const [timeSpanRows, setTimeSpanRows] = useState([]);
    const history = useHistory();

    const handleSubmit = () => {
        if (site) {
            api.addNewKiosk(
                props.organisationId, props.match.params.siteId,
                kioskName, timeSpanRows, webKioskEnabled, kioskAppEnabled,
                () => history.push(`/organisation/${ props.organisationId }/client/${ site.clientId }/site/${ props.match.params.siteId }`),
                error => console.log(error),
                props.token
            )
        }
    }

    const updateTimeSpanRow = (id, value, mode) => setTimeSpanRows(timeSpanRows.toSpliced(id, 1, { type: mode, duration: value }))

    const fetchSite = () => {
        api.getSite(
            data => setSite(data),
            () => { },
            props.match.params.siteId,
            props.organisationId,
            props.token
        )
    }

    useEffect(() => fetchSite(), [])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h5 className="mb-0">Add New Kiosk</h5>
                                </div>
                            </div>
                        </div>

                        {
                            site &&
                            <div className="card-body bg-light border-top details-container">
                                <div className="row">
                                    <div className="col-md-3 col-12">
                                        <p className="font-weight-semi-bold mb-3">Kiosk Name<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-6 col-12 px-0">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" value={ kioskName } onChange={ (e) => setKioskName(e.target.value) } />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-3 col-12">
                                        <p className="font-weight-semi-bold mb-3">Web Kiosk Enabled</p>
                                    </div>
                                    <div className="col-md-6 col-12 custom-control custom-switch">
                                        <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            id="web-kiosk-enabled"
                                            checked={ webKioskEnabled }
                                            onChange={ () => setWebKioskEnabled(!webKioskEnabled) } />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="web-kiosk-enabled"
                                        ></label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-3 col-12">
                                        <p className="font-weight-semi-bold mb-3">Kiosk App Enabled</p>
                                    </div>
                                    <div className="col-md-6 col-12 custom-control custom-switch ">
                                        <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            id="kiosk-app-enabled"
                                            checked={ kioskAppEnabled }
                                            onChange={ () => setKioskAppEnabled(!kioskAppEnabled) } />
                                        <label className="custom-control-label" htmlFor="kiosk-app-enabled"></label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-3 col-12">
                                        <p className="font-weight-semi-bold mb-3">Time Spans<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-2 col-12 pl-0">
                                        <KioskTimeConfig
                                            updateList={ newList => setTimeSpanRows(newList) }
                                            handleAddRow={ (value, mode) => {
                                                const payloadValue = mode === 3 ? null : value;
                                                setTimeSpanRows(prevState => [...prevState, { type: parseInt(mode), duration: payloadValue }])
                                            } }
                                            handleUpdateRow={ (id, value, mode) => updateTimeSpanRow(id, value, mode) }
                                            handleDeleteRow={ id => setTimeSpanRows(timeSpanRows.toSpliced(id, 1)) }
                                            data={ timeSpanRows }
                                            editMode={ true }
                                        />
                                    </div>
                                </div>

                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-6 col-sm-auto ml-auto pl-0">
                                            <div id="dashboard-actions">
                                                <Link to={ `/organisation/${ props.organisationId }/client/${ site.clientId }/site/${ props.match.params.siteId }` } className="btn btn-falcon-default btn-sm" type="button"><i className="fas fa-times"></i> Cancel</Link>
                                                <button
                                                    type="button"
                                                    disabled={timeSpanRows.length === 0 || stringHelpers.isNullOrEmpty(kioskName)}
                                                    className="btn btn-success btn-sm ml-2"
                                                    onClick={ () => handleSubmit() }>
                                                    <i className="fas fa-check"></i> Confirm
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}