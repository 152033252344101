import { useEffect, useState } from "react";
import { KioskTimeOption } from "./kioskTimeOption";
import Enums from "../../utilities/enum";
import { DndContext, closestCorners, PointerSensor, useSensors, useSensor } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";

export const KioskTimeConfig = (props) => {
	const [inputValue, setInputValue] = useState("0");
	const [mode, setMode] = useState("0");
	const [editModalContent, setEditModalContent] = useState(null);
	const [sortingArray, setSortingArray] = useState(null);

	const sensors = useSensors(
		useSensor(PointerSensor, {
		  activationConstraint: {
			distance: 5,
		  },
	}))

	useEffect(() => {
		setSortingArray(props.data.map((item, index) => ({
			id: String(index),
			...item
		})));
	}, [props.data])

	const updateModal = (id, value, mode) => {
		setEditModalContent({
			id: Number(id),
			value: value,
			mode: mode,
		});
		setMode(mode);
		setInputValue(value);
	};

	const getElementPosition = (id) => sortingArray.findIndex((item) => item.id === id);

	const handleDragEndTable = (event) => {
		if (props.editMode) {
			const { active, over } = event;
			if (active.id === over.id) return;
			const originalPosition = getElementPosition(active.id);
			const newPosition = getElementPosition(over.id);
			props.updateList((prevState) => {
				const reOrderedArray = arrayMove(sortingArray, originalPosition, newPosition);
				return reOrderedArray.map(item => {
					const {id, ...rest} = item;
					return rest;
				})
			});
		}
	};

	return (
		<div className="d-flex flex-column mb-3">
			{/* Add Time span modal */}
			<div
				className="modal fade"
				id="add-time-span-modal"
				role="dialog"
				aria-labelledby="Add Time Span Option Modal"
				aria-hidden="true">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="addTimeSpanOptionLabel">
								Add a Time Span Option.
							</h5>
							<button
								type="button"
								className="close"
								data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-12">
									<div className="form-group mb-0">
										<div className="d-flex flex-column mb-3">
											<p>Time Span Mode</p>
											<select
												onChange={(e) => {
													setMode(parseInt(e.target.value))
												}}
												id="mode-select"
												className="border py-1 rounded">
												{Object.entries(Enums.TimeSpanMode).map(
													([k, v]) => (
														<option selected={k === mode} value={k} key={k}>
															{v}
														</option>
													)
												)}
											</select>
										</div>
										{
											mode !== 3 && (
												<div className="d-flex flex-column">
													<p for="time-value-input">Provide a value</p>
													<input
														min={0}
														className="border py-1 rounded"
														onChange={(e) => setInputValue(parseInt(e.target.value))}
														id="time-value-input"
														type="number"
														value={inputValue}
													/>
												</div>
											)
										}
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								onClick={() => {
									setEditModalContent(null);
									setInputValue("0");
									setMode("0");
								}}
								type="button"
								className="btn btn-falcon-default btn-sm ml-2"
								data-dismiss="modal">
								Cancel
							</button>
							<button
								type="button"
								className="btn btn-success btn-sm ml-2"
								data-dismiss="modal"
								onClick={() => {
									props.handleAddRow(inputValue, mode);
									setEditModalContent(null);
									setInputValue("0");
									setMode("0");
								}}>
								Confirm
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* Update Time span modal */}
			{editModalContent && (
				<div
					className="modal fade"
					id="update-time-span-modal"
					role="dialog"
					aria-labelledby="Update Time Span Option Modal"
					aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="updateTimeSpanOptionLabel">
									Update Time Span Option.
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-12">
										<div className="form-group mb-0">
											<div className="d-flex flex-column mb-3">
												<p>Select a Time Span Mode</p>
												<select
													onChange={(e) => {
														setMode(parseInt(e.target.value));
														if (e.target.value === "3") setInputValue(null);
													}}
													defaultValue={editModalContent.mode}
													id="mode-update"
													className="border py-1 rounded">
													{Object.entries(Enums.TimeSpanMode).map(
														([k, v]) => 
														<option selected={k === editModalContent.mode} key={k} value={k === editModalContent.mode ? editModalContent.mode : k }>
															{v}
														</option>
													)}
												</select>
											</div>
											{
												mode !== 3 && (
													<div className="d-flex flex-column">
														<p for="time-value-input">Provide a value</p>
														<input
															min={0}
															onChange={(e) => setInputValue(parseInt(e.target.value))}
															id="time-value-input"
															type="number"
															className="border py-1 rounded px-2"
															value={inputValue}
														/>
													</div>
												)
											}
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button
									onClick={() => {
										setEditModalContent(null);
										setInputValue("0");
										setMode("0");
									}}
									type="button"
									className="btn btn-falcon-default btn-sm ml-2"
									data-dismiss="modal">
									Cancel
								</button>
								<button
									type="button"
									className="btn btn-success btn-sm ml-2"
									data-dismiss="modal"
									onClick={() => {
										props.handleUpdateRow(
											editModalContent.id,
											inputValue,
											mode
										);
										setEditModalContent(null);
										setInputValue("0");
										setMode("0");
									}}>
									Confirm
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* Delete Time span modal */}
			{editModalContent && (
				<div
					className="modal fade"
					id="delete-time-span-modal"
					role="dialog"
					aria-labelledby="Delete Time Span Option Modal"
					aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="deleteTimeSpanOptionLabel">
									Are you sure you want to delete this time option?
								</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-footer">
								<button
									onClick={() => {
										setEditModalContent(null);
										setInputValue("0");
										setMode("0");
									}}
									type="button"
									className="btn btn-falcon-default btn-sm ml-2"
									data-dismiss="modal">
									Cancel
								</button>
								<button
									type="button"
									className="btn btn-danger btn-sm ml-2"
									data-dismiss="modal"
									onClick={() => {
										props.handleDeleteRow(editModalContent.id);
										setEditModalContent(null);
										setInputValue("0");
										setMode("0");
									}}>
									Confirm
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{
				sortingArray && (
					<DndContext sensors={sensors} onDragEnd={handleDragEndTable} collisionDetection={closestCorners}>
						<table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer">
							<SortableContext items={sortingArray}
											strategy={verticalListSortingStrategy}>
								<tbody>
									{sortingArray.map((dataPoint, index) => {
											return (
												<KioskTimeOption
													id={dataPoint.id}
													editMode={props.editMode}
													key={index}
													handleUpdateModal={(id, value, mode) => {
														updateModal(id, value, mode);
													}}
													option={dataPoint}
												/>
											);
									})}
								</tbody>
							</SortableContext>
						</table>
					</DndContext>
				)
			}
			{props.editMode && (
				<button
					onClick={() => {
						setInputValue("0");
						setMode("0");
					}}
					className="btn btn-success btn-sm align-self-start mt-2"
					data-toggle="modal"
					data-target="#add-time-span-modal">
				<i className="fas fa-plus mr-1"></i>Add
			</button>
			)}
		</div>
	);
};