import React from 'react';

function Loader({classAddOn}) {
    return (
            <div className={`spinner-wrapper ${classAddOn}`}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
    )
}

export default Loader;