import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import StringHelpers from '../../utilities/stringHelpers';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import Loader from '../shared/loader';
import { useToasts } from 'react-toast-notifications'
import UploadFile from '../shared/file-uploader';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Payments from './_payments';
import BreachPayments from './_breachPayments';
import RedactionPane from './_redactionPane';
import OutstandingBalancePane from './_outstandingBalancePane';
import roleUtilities from '../../utilities/roleUtilities';
import AllowedVrms from './_allowedVrms';
import arrayUtilities from '../../utilities/arrayUtilities';

var cloneDeep = require('lodash.clonedeep');

function Breach(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [breachId, setBreachId] = useState(props.match.params.breachId)
    const [breach, setBreach] = useState(null);
    const [breaches, setBreaches] = useState(null);
    const [site, setSite] = useState(null);
    const [allocatedBreaches, setAllocatedBreaches] = useState(null);

    const [numberOfBreaches, setNumberOfBreaches] = useState(0);
    const [durationHour, setDurationHour] = useState(0);
    const [durationMin, setDurationMin] = useState(0);
    const [durationSec, setDurationSec] = useState(0);
    const [message, setMessage] = useState("");

    const [allContraventions, setAllContraventions] = useState(null);
    const [siteContraventions, setSiteContraventions] = useState(null);
    const [contraventionEdit, setContraventionEdit] = useState(null);

    const [dupeTicket, setDupeTicket] = useState(null);
    const [isVrmInEdit, setIsVrmInEdit] = useState(false);
    const [vrm, setVrm] = useState("");
    const [contraventionId, setContraventionId] = useState(null);

    const [vehicleMake, setVehicleMake] = useState("");
    const [vehicleModel, setVehicleModel] = useState("");
    const [colour, setColour] = useState("");
    const [driverName, setDriverName] = useState("");
    const [driverMobileNumber, setDriverMobileNumber] = useState("");
    const [driverEmailAddress, setDriverEmailAddress] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [indefinite, setIndefinite] = useState(false);
    const [reason, setReason] = useState("");
    const [canSave, setCanSave] = useState(false);

    const [note, setNote] = useState("");
    const [log, setLog] = useState(null);
    const [reasons, setReasons] = useState(null);
    const [cancelReason, setCancelReason] = useState(null);
    const [canCancel, setCanCancel] = useState(false);

    const [lookupDataInEdit, setLookupDataInEdit] = useState(false);
    const [lookupColour, setLookupColour] = useState("");
    const [lookupModel, setLookupModel] = useState("");
    const [lookupMake, setLookupMake] = useState("");

    const [attachments, setAttachments] = useState(null);
    const [contactDetailsList, setContactDetailsList] = useState(null);
    const [newContactUrl, setNewContactUrl] = useState("/");
    const [status, setStatus] = useState(null);
    const [showChangeStatus, setShowChangeStatus] = useState(false);

    const [appeals, setAppeals] = useState(null);
    const [transfers, setTransfers] = useState(null);
    const [transferUrl, setTransferUrl] = useState("/");
    const [previousContraventions, setPreviousContraventions] = useState(null);

    const [prevUrl, setPrevUrl] = useState("/");
    const [nextUrl, setNextUrl] = useState("/");

    const { addToast } = useToasts();
    const history = useHistory();

    const [showAppeal, setShowAppeal] = useState(false);
    const [showTransfer, setShowTransfer] = useState(false);

    const handleCloseAppeal = () => setShowAppeal(false);
    const handleShowAppeal = () => setShowAppeal(true);
    const handleCloseTransfer = () => setShowTransfer(false);
    const handleShowTransfer = () => setShowTransfer(true);

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    var _breachesAbortController = null;
    var _allocatedBreachesAbortController = null;

    const exportToCSV = (csvData) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var fileName = 'Previous_Contraventions_' + vrm.toString();
        FileSaver.saveAs(data, fileName + fileExtension);
    };


    const getReasons = function () {
        Api.getCancellationReasons(
            (data) => setReasons(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    const getSiteContraventions = function () {
        Api.getSiteContraventions(
            (data) => setSiteContraventions(data),
            (error) => console.log(error),
            props.organisationId,
            breach.siteId,
            props.token
        )
    };

    const getAllContraventions = function () {
        Api.getContraventions(
            (data) => setAllContraventions(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    const download = function (appeal) {

    };

    const getPreviousContraventions = function () {
        if (vrm !== null && vrm !== "") {
            Api.getBreaches(
                (data) => {
                    if (data && data !== null && data.items !== null && data.items.length > 0) {
                        setPreviousContraventions(data.items.filter(x => x.id !== breachId))
                    } else {
                        setPreviousContraventions([]);
                    }
                },
                (error) => console.log(error),
                vrm, null, null, null, null, null, null, null, 0,
                props.organisationId,
                props.token,
                null
            )
        }
    };

    const getBreach = function () {
        setIsLoading(true);
        Api.getBreach(
            (data) => {
                setBreach(data);
                setIsLoading(false);
            },
            (error) => console.log(error),
            breachId,
            props.organisationId,
            props.token
        )
    };

    const getAttachments = function () {
        Api.getBreachAttachments(
            (data) => setAttachments(data),
            (error) => console.log(error),
            props.organisationId,
            breachId, props.token
        )
    };

    const getBreaches = function () {
        if (_breachesAbortController) {
            _breachesAbortController.abort();
        }
        _breachesAbortController = new AbortController();
        const signal = _breachesAbortController.signal;
        Api.getBreaches(
            (data) => setBreaches(data.items),
            (error) => console.log(error),
            breach.vrm, null, null, null, null, null, null, null, 0,
            props.organisationId,
            props.token,
            signal
        )
    };

    const getSite = function () {
        Api.getSite(
            (data) => setSite(data),
            () => { },
            breach.siteId, props.organisationId, props.token);
    }

    const ticketAction = function (content, toastType) {
        addToast(content, {
            appearance: toastType,
            autoDismiss: true,
        })
    };

    const voidTicket = function () {
        Api.voidTicket(
            () => {
                getBreach();
                addToast("Ticket Voided Successfully", {
                    appearance: 'error',
                    autoDismiss: true,
                });
            },
            (error) => console.log(error),
            breachId,
            props.organisationId,
            props.token);

    };

    const setOnHold = function (onHold) {
        Api.onHoldTicket(
            () => {
                getBreach();
                getLog();
                setMessage("")
            },
            (error) => console.log(error),
            message,
            breachId,
            onHold,
            props.organisationId,
            props.token
        )
    };

    const submit = function () {
        if (canSave) {
            Api.addAllowedVrm(
                () => voidTicket(),
                (error) => {
                    ticketAction(error, 'error')
                },
                vrm,
                vehicleMake,
                vehicleModel,
                breach.parkingSession.siteName,
                driverName,
                driverMobileNumber,
                startDate,
                endDate,
                reason,
                breach.parkingSession.siteId,
                null,
                null,
                props.organisationId,
                props.token
            );
        }
    };

    const sendToDvla = function () {
        setIsLoading(true);
        Api.sendTicketToDvla(
            () => {
                getBreach();
                ticketAction("DVLA requested", 'info');
            },
            (error) => ticketAction(error, 'error'),
            props.organisationId,
            breachId,
            props.token
        )
    };

    const attachFile = function (file) {
        Api.addBreachAttachment(
            () => getAttachments(),
            file.id, breachId, props.organisationId, props.token
        )
    };

    const getAllocatedBreaches = function () {
        if (_allocatedBreachesAbortController) {
            _allocatedBreachesAbortController.abort();
        }
        _allocatedBreachesAbortController = new AbortController();
        const signal = _allocatedBreachesAbortController.signal;
        Api.getBreaches(
            (data) => setAllocatedBreaches(data.items),
            (error) => console.log(error),
            null, null, props.user.id, true, null, null, null, null, 0,
            props.organisationId,
            props.token,
            signal
        )
    };

    const submitNote = function () {
        if (note !== "") {
            Api.attachNoteToTicket(
                () => {
                    getLog();
                    setNote(null);
                },
                (error) => console.log(error),
                note, breachId,
                props.organisationId,
                props.token
            )
        }
    };

    const getAppeals = function () {
        Api.getAppeals(
            (data) => setAppeals(data),
            (error) => console.log(error),
            breachId,
            props.organisationId,
            props.token
        )
    };

    const editBreach = function () {
        Api.editBreach(
            (data) => {
                setBreach(data);
                setLookupDataInEdit(false);
                setContraventionEdit(false);
            },
            vrm, lookupMake, lookupModel, lookupColour, (contraventionEdit ? contraventionId : breach.contraventionId), breachId, props.organisationId, props.token
        )
    };

    const getTransfers = function () {
        Api.getTransfersLiability(
            (data) => setTransfers(data),
            (error) => { },
            breachId,
            props.organisationId,
            props.token
        )
    };

    const cancelTicket = function () {
        if (canCancel) {
            Api.cancelTicket(
                () => {
                    getBreach();
                    ticketAction('Ticket Cancelled Successfully', 'error');
                },
                (error) => console.log(error),
                cancelReason.id,
                props.organisationId,
                breachId,
                props.token
            )
        }
    };

    const readyForLetters = function () {
        if (contactDetailsList.length !== 0) {
            Api.setReadyForLetters(
                (data) => {
                    getBreach();
                },
                (error) => console.log(error),
                "", breachId, props.organisationId, props.token
            )
        }
    };

    const getLog = function () {
        Api.getLog(
            (data) => setLog(data),
            (error) => console.log(error),
            breachId,
            props.organisationId,
            props.token
        )
    };

    const getContactDetails = function () {
        Api.getContactDetails(
            (data) => setContactDetailsList(data),
            (error) => console.log(error),
            breachId, props.organisationId, props.token
        )
    };

    const cancelChangeStatus = function () {
        setStatus(breach.breachStatus);
        setNote(null);
        setShowChangeStatus(false);
    };

    const changeStatus = function () {
        setShowChangeStatus(false);

        Api.setBreachStatus(
            () => {
                getBreach();
                setNote(null);
            },
            (error) => console.log(error),
            note,
            breachId,
            status,
            props.organisationId,
            props.token
        )
    };

    const onSaveReducedCharge = function (reducedChargeDays, reducedChargeAmount) {
        setIsLoading(true);

        Api.setBreachReducedCharge(
            () => {
                getBreach();
            },
            (error) => console.log(error),
            breachId,
            reducedChargeDays,
            reducedChargeAmount,
            props.organisationId,
            props.token
        );
    }

    useEffect(() => {
        if (breach !== null) {
            setVrm(breach.vrm);
            setStatus(breach.breachStatus);
            if (breach.parkingSession !== null && breach.parkingSession !== undefined) {
                var exit = moment(breach.parkingSession.exit.timestamp);
                var entry = moment(breach.parkingSession.entry.timestamp);

                var hours = exit.diff(entry, 'hours');
                setDurationHour(hours);
                entry.add(hours, 'hours');
                var mins = exit.diff(entry, 'minutes');
                setDurationMin(mins)
                entry.add(mins, 'minutes')
                var secs = exit.diff(entry, 'seconds');
                setDurationSec(secs)
            }

            if (breaches && breaches !== null && breaches.length > 0) {
                var total = 0;
                var date = moment(breach.timestamp);

                breaches.forEach(item => {
                    if (item.vrm === breach.vrm) {
                        total++;
                        var dupeDate = moment(item.timestamp);
                        if (dupeDate.date() === date.date() && dupeDate.month() === date.month() && item.id !== breach.id) {
                            setDupeTicket(item)
                        }
                    }
                });
                setNumberOfBreaches(total);
            }
        } else {
            getBreach();
        }
    }, [breach, breaches]);

    useEffect(() => {

        if (breach !== null && !StringHelpers.isNullOrEmpty(contraventionId)) {
            setContraventionEdit(contraventionId !== breach.contraventionId);
        }
    }, [contraventionId]);

    useEffect(() => {
        if (vrm !== null) {
            setVrm(vrm.toUpperCase());
        }
    }, [vrm]);

    const addNewAppeal = function () {
        history.push("/organisation/" + props.organisationId + "/breach/" + breachId + "/appeal");
    };

    const deleteAppeal = function (appealId) {
        Api.deleteAppeal(() => {
            getBreach();
        }, () => {
            alert("Error deleting appeal");
        }, breachId, appealId, props.organisationId, props.token);
    }

    const addNewTransfer = function () {
        history.push("/organisation/" + props.organisationId + "/breach/" + breachId + "/transfer-of-liability");
    };

    const attachmentIsLetterImage = function (attachmentId, isLetterImage) {
        let attachmentsClone = cloneDeep(attachments);
        let targetIndex = attachmentsClone.findIndex(x => x.id === attachmentId);

        if (targetIndex >= 0) {
            attachmentsClone[targetIndex].isLetterImage = isLetterImage;
        }

        setAttachments(attachmentsClone);
        Api.setBreachAttachmentIsLetterImage(() => {

        }, () => {

        }, props.organisationId, props.id, attachmentId, isLetterImage, props.token)
    }

    useEffect(() => {
        if (!isLoading && breach && breach !== null && status !== breach.breachStatus) {
            if (status === 200) {
                handleShowAppeal();
            } else if (status === 210) {
                handleShowTransfer();
            } else {
                setShowChangeStatus(true);
            }
        }
    }, [status]);

    useEffect(() => {
        if (cancelReason !== null) {
            setCanCancel(true);
        } else {
            setCanCancel(false);
        }
    }, [cancelReason]);

    useEffect(() => {
        setBreachId(props.match.params.breachId);
    }, [props.match.params.breachId])

    useEffect(() => {
        if (allocatedBreaches !== null && allocatedBreaches.length !== 0 && breachId !== null) {
            var i = 0;
            var index = null;
            allocatedBreaches.forEach(item => {
                if (item.id === breachId) {
                    index = i;
                }
                i++;
            });
            if (index !== null) {
                if (index !== 0) {
                    setPrevUrl("/organisation/" + props.organisationId + "/breach/" + allocatedBreaches[index - 1].id);
                } else {
                    setPrevUrl("/organisation/" + props.organisationId + "/breach/" + allocatedBreaches[allocatedBreaches.length - 1].id);
                }
                if (index !== allocatedBreaches.length - 1) {
                    setNextUrl("/organisation/" + props.organisationId + "/breach/" + allocatedBreaches[index + 1].id);
                } else {
                    setNextUrl("/organisation/" + props.organisationId + "/breach/" + allocatedBreaches[0].id);
                }
            } else {
                setPrevUrl("/organisation/" + props.organisationId + "/breach/" + allocatedBreaches[0].id);
                setNextUrl("/organisation/" + props.organisationId + "/breach/" + allocatedBreaches[allocatedBreaches.length - 1].id);
            }
        }
    }, [allocatedBreaches]);

    useEffect(() => {
        if (breachId !== null) {
            getBreach();
            setNewContactUrl("/organisation/" + props.organisationId + "/breach/" + breachId + "/contact-details");
        }
        getAllContraventions();
        getReasons();
    }, [breachId]);

    useEffect(() => {
        if (breach !== null) {
            if (breach.vehicleMake !== null) {
                setLookupMake(breach.vehicleMake);
            } else {
                setLookupMake(null);
            }
            if (breach.vehicleColour !== null) {
                setLookupColour(breach.vehicleColour);
            } else {
                setLookupColour(null);
            }
            if (breach.vehicleModel !== null) {
                setLookupModel(breach.vehicleModel);
            } else {
                setLookupModel(null);
            }
        }
    }, [lookupDataInEdit, breach]);

    const reloadImages = function () {
        getBreach();
    };

    useEffect(() => {
        if (breach !== null) {
            getBreaches();
            getSite();
            getSiteContraventions();
        }
        getAllocatedBreaches();
        getAppeals();
        getAttachments();
        getContactDetails();
        getLog();
        getPreviousContraventions();
        getTransfers();
    }, [breach]);

    useEffect(() => {
        if (!indefinite && (startDate === null || endDate === null || startDate === "" || endDate === "")) {
            setCanSave(false);
        } else {
            setCanSave(true);
        }
    }, [indefinite, startDate, endDate]);

    useEffect(() => {
        if (transfers !== null) {
            setTransferUrl("/organisation/" + props.organisationId + "/breach/" + breachId + "/transfer-of-liability");
        };
    }, [transfers]);

    return (
        <>
            {isLoading &&
                <Loader />
            }
            {!isLoading && breach && breach !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card breach-header">
                                <div className="card-header ">
                                    <div className="row align-items-center justify-content-between ">
                                        <div className="col d-flex align-items-center pr-0">
                                            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0 d-flex align-items-center">
                                                {prevUrl !== "/" &&
                                                    <Link className="btn btn-sm mr-1" to={prevUrl}><i className="fas fa-2x fa-angle-left"></i></Link>}
                                                Breach -
                                                {breach.referenceNumber !== null && <> {breach.referenceNumber}</>}
                                                {breach.referenceNumber === null && <p className="small-number-plate m-0 ml-2"> {breach.vrm}</p>}
                                                {nextUrl !== "/" &&
                                                    <Link className="btn btn-sm" to={nextUrl}><i className="fas fa-2x fa-angle-right"></i></Link>}
                                            </h5>
                                        </div>
                                        <div className="col-auto text-right pl-0 filter-buttons-wrapper">
                                            <button className="btn btn-default btn-sm ml-1" onClick={getBreach}><i className="fas fa-redo"></i> Refresh</button>
                                            {breach.breachStatus === 400 &&
                                                <button className="btn btn-success btn-sm ml-1" onClick={() => setStatus(402)}><i className="fas fa-play"></i> Send to debt recovery</button>
                                            }
                                            {!breach.isOnHold &&
                                                <button className="btn btn-primary btn-sm ml-1" data-toggle="modal" data-target="#on-hold-modal"><i className="fas fa-pause"></i> Place on Hold</button>
                                            }
                                            {breach.isOnHold &&
                                                <button className="btn btn-primary btn-sm ml-1" onClick={() => setOnHold(false)}><i className="fas fa-play"></i> Take off Hold</button>
                                            }
                                            {breach.breachStatus === 0 &&
                                                <button className="btn btn-primary btn-sm ml-1" onClick={() => sendToDvla()}><i className="fas fa-wrench"></i> DVLA Request</button>}
                                            <button className="btn btn-danger btn-sm ml-1" data-toggle="modal" data-target="#cancel-modal"><i className="fas fa-times"></i> Cancel Ticket</button>
                                            <button className="btn btn-danger btn-sm ml-1" onClick={() => voidTicket()}><i className="fas fa-times"></i> Void Ticket</button>
                                            <button className="btn btn-success btn-sm ml-1" data-toggle="modal" data-target="#allow-list-modal"><i className="fas fa-plus"></i> Add to Allow List</button>
                                            {contactDetailsList !== null && contactDetailsList.length !== 0 &&
                                                <button className="btn btn-success btn-sm ml-1" onClick={() => readyForLetters()}><i className="fas fa-check"></i> Ready for Letters</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3 mt-5">
                                <div className="modal fade" id="cancel-modal" tabIndex="-1" role="dialog" aria-labelledby="Cancel Modal" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="deleteModalLabel">Select a reason to cancel this ticket.</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-2">Reason:</p>
                                                    </div>
                                                    <div className="col-12 ">
                                                        <div className="form-group mb-0">
                                                            <div className="dropdown ">
                                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{cancelReason !== null && cancelReason.name}{cancelReason === null && <>Select Reason</>}</button>
                                                                <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-type" >
                                                                    <div className="bg-white py-2 rounded-soft">
                                                                        {reasons !== null && reasons.map((reason) => {
                                                                            return (
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCancelReason(reason)}>{reason.name}</button>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canCancel} data-dismiss="modal" onClick={() => cancelTicket()}>
                                                    Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="on-hold-modal" tabIndex="-1" role="dialog" aria-labelledby="On Hold Modal" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="deleteModalLabel">Add a note to place this ticket On Hold.</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-2">Note:</p>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mb-0">
                                                            <textarea className="form-control mb-0" rows={4} value={message} onChange={(e) => setMessage(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" onClick={() => setOnHold(true)}>
                                                    Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Modal show={showChangeStatus} onHide={cancelChangeStatus} size="lg">
                                    <Modal.Header>
                                        <Modal.Title>
                                            Add a note to set status to {Enums.BreachStatus[status]}
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="row">
                                            <div className="col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-2">Note:</p>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group mb-0">
                                                    <textarea className="form-control mb-0" rows={4} value={note} onChange={(e) => setNote(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button className="btn btn-sm btn-danger mt-1" onClick={cancelChangeStatus}>Cancel</button>
                                        <button className="btn btn-sm btn-success mt-1" disabled={StringHelpers.isNullOrEmpty(note)} onClick={changeStatus}>Confirm</button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showAppeal} onHide={handleCloseAppeal}>
                                    <Modal.Header>
                                        <Modal.Title>
                                            <div className="row justify-content-between">
                                                <div className="col-11">
                                                    Do you want to add a new Appeal?
                                                </div>
                                                <div className="col-1">
                                                    <button type="button" className="close" onClick={handleCloseAppeal}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Footer>
                                        <button className="btn btn-sm btn-danger mt-1" onClick={() => { handleCloseAppeal(); setStatus(breach.breachStatus); }}>Cancel</button>
                                        <button className="btn btn-sm btn-success mt-1" onClick={() => { handleCloseAppeal(); addNewAppeal(); }}>Confirm</button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showTransfer} onHide={handleCloseTransfer}>
                                    <Modal.Header>
                                        <Modal.Title>
                                            <div className="row justify-content-between">
                                                <div className="col-11">
                                                    Do you want to add a new Transfer of Liability?
                                                </div>
                                                <div className="col-1 text-right">
                                                    <button type="button" className="close" onClick={handleCloseTransfer}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Footer>
                                        <button className="btn btn-sm btn-danger mt-1" onClick={() => { handleCloseTransfer(); setStatus(breach.breachStatus); }}>Cancel</button>
                                        <button className="btn btn-sm btn-success mt-1" onClick={() => { handleCloseTransfer(); addNewTransfer(); }}>Confirm</button>
                                    </Modal.Footer>
                                </Modal>
                                <div className="modal fade allow-list-modal" id="allow-list-modal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="deleteModalLabel">Add to Allow List</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">VRM*</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 ">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-1 small-number-plate" type="text" value={vrm} onChange={(e) => setVrm(e.target.value.toUpperCase())} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Vehicle Make</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 ">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="text" value={vehicleMake} onChange={(e) => setVehicleMake(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Vehicle Model</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 ">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="text" value={vehicleModel} onChange={(e) => setVehicleModel(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Colour</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 ">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="text" value={colour} onChange={(e) => setColour(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Site</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">{breach.siteName}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Driver Name</p>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group mb-0">
                                                            <input className="form-control mb-1" type="text" value={driverName} onChange={(e) => setDriverName(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Driver Mobile Number</p>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group mb-0">
                                                            <input className="form-control mb-1" type="text" value={driverMobileNumber} onChange={(e) => setDriverMobileNumber(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Driver Email Address</p>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="text" value={driverEmailAddress} onChange={(e) => setDriverEmailAddress(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold mb-1 ">Indefinite Period</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 custom-control custom-switch">
                                                        <input className="custom-control-input" type="checkbox" id="switch1" checked={indefinite} onChange={() => {
                                                            if (!indefinite) {
                                                                setStartDate(null);
                                                                setEndDate(null);
                                                            }
                                                            setIndefinite(!indefinite)
                                                        }} />
                                                        <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                                    </div>
                                                </div>
                                                {!indefinite && <>
                                                    <div className="row">
                                                        <div className="col-md-4 col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Period Start*</p>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <div className="form-group mb-0">
                                                                <input className="form-control mb-1" type="datetime-local" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Period End*</p>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <div className="form-group mb-0">
                                                                <input className="form-control mb-1" type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Reason</p>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group mb-1">
                                                            <textarea className="form-control mb-0" rows={3} value={reason} onChange={(e) => setReason(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">
                                                    <i className="fas fa-times"></i> Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} data-dismiss="modal" onClick={() => submit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    <div className="row">
                                        <div className='col-sm-6'>
                                            <div className="row mb-2">
                                                <label class="col-sm-4 col-form-label col-form-label-sm">Breach Date</label>
                                                <div className="col-sm-8">
                                                    {breach.timestamp !== null &&
                                                        <p class="form-control-plaintext form-control-sm outline-none" >{moment(breach.timestamp).format("DD/MM/yyyy")} ({moment(breach.timestamp).fromNow()})</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <label class="col-sm-4 col-form-label col-form-label-sm">Breach Status</label>
                                                <div className=" col-sm-8">
                                                    {roleUtilities.isOrganisatoinAdminOrManager(props.user, props.organisationId) ?
                                                        <select class="custom-select custom-select-sm" onChange={(e) => setStatus(Number(e.target.value))}>
                                                            {Object.keys(Enums.BreachStatus).map((item) =>
                                                                <option selected={status === Number(item)} value={item}>{Enums.BreachStatus[item]}</option>
                                                            )}
                                                        </select> :
                                                        <p class="form-control-plaintext form-control-sm outline-none" >{Enums.BreachStatus[status]}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <label class="col-sm-4 col-form-label col-form-label-sm">Site</label>
                                                <div className="col-sm-8">
                                                    <p class="form-control-plaintext form-control-sm outline-none" >{breach.siteName}</p>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <label class="col-sm-4 col-form-label col-form-label-sm">Assigned To</label>
                                                <div className="col-sm-8">
                                                    <p class="form-control-plaintext form-control-sm outline-none" >    {breach.assignedToUserName}{breach.assignedToUserName === null && <>Unassigned</>}</p>
                                                </div>
                                            </div>
                                            {!lookupDataInEdit && <>

                                                <div className="row align-items-center mb-2">
                                                    <label class="col-sm-4 col-form-label col-form-label-sm">VRM</label>
                                                    <div className="col-sm-8">
                                                        <p className="small-number-plate m-0">{breach.vrm}</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label class="col-sm-4 col-form-label col-form-label-sm">Vehicle Make</label>
                                                    <div className="col-sm-8">
                                                        <p class="form-control-plaintext form-control-sm outline-none" >{lookupMake}</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label class="col-sm-4 col-form-label col-form-label-sm">Vehicle Model</label>
                                                    <div className="col-sm-8">
                                                        <p class="form-control-plaintext form-control-sm outline-none" >{lookupModel}</p>
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label class="col-sm-4 col-form-label col-form-label-sm">Vehicle Colour</label>
                                                    <div className="col-sm-8">
                                                        <p class="form-control-plaintext form-control-sm outline-none" >{lookupColour}</p>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="offset-md-4 col-auto">
                                                        <button className="btn-sm btn btn-falcon-default" onClick={() => setLookupDataInEdit(true)}><i className="fas fa-edit"></i> Edit Vehicle Details</button>
                                                    </div>
                                                </div>
                                            </>}
                                            {lookupDataInEdit && <>
                                                <div className="row align-items-center mb-2">
                                                    <label class="col-sm-4 col-form-label col-form-label-sm">VRM</label>
                                                    <div className="col-sm-8">
                                                        <input className="form-control form-control-sm" type="text" value={vrm} onChange={(e) => setVrm(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mb-2">
                                                    <label class="col-sm-4 col-form-label col-form-label-sm">Vehicle Make</label>
                                                    <div className="col-sm-8">
                                                        <input className="form-control form-control-sm" type="text" value={lookupMake} onChange={(e) => setLookupMake(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mb-2">
                                                    <label class="col-sm-4 col-form-label col-form-label-sm">Vehicle Model</label>

                                                    <div className="col-sm-8">
                                                        <input className="form-control form-control-sm" type="text" value={lookupModel} onChange={(e) => setLookupModel(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mb-2">
                                                    <label class="col-sm-4 col-form-label col-form-label-sm">Vehicle Colour</label>
                                                    <div className="col-sm-8">
                                                        <input className="form-control form-control-sm" type="text" value={lookupColour} onChange={(e) => setLookupColour(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="offset-sm-4 col-auto">
                                                        <button className="btn-sm btn btn-danger mr-1" onClick={() => setLookupDataInEdit(false)}><i className="fas fa-times"></i> Cancel</button>
                                                        <button className="btn-sm btn btn-success mx-1" onClick={() => editBreach()}><i className="fas fa-check"></i> Confirm</button>
                                                    </div>
                                                </div>
                                            </>}
                                        </div>
                                        <div className="col-sm-6">
                                            <OutstandingBalancePane breach={breach} onSave={onSaveReducedCharge} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-1 d-flex align-items-center pr-0">
                                            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Appeals</h5>
                                        </div>
                                        <div className="col-2 text-right pl-0 filter-buttons-wrapper">
                                            <button className="btn btn-success btn-sm ml-1" onClick={addNewAppeal}><i className="fas fa-plus"></i> Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container p-0">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Contact Type</th>
                                                <th>Contact Source</th>
                                                <th className="text-center">Status</th>
                                                <th className="text-center">Date/Time</th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        {appeals !== null && appeals.length > 0 &&
                                            <tbody>
                                                {appeals.map((appeal) => {
                                                    var appealUrl = "/organisation/" + props.organisationId + "/breach/" + breachId + "/appeal/" + appeal.id;
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td><Link to={appealUrl}><h6 className="my-0">{appeal.contactDetails.firstName} {appeal.contactDetails.lastName}</h6></Link></td>
                                                                <td>{Enums.ContactTypes[appeal.contactDetails.contactType]}</td>
                                                                <td>{Enums.ContactSource[appeal.contactDetails.contactSource]}</td>
                                                                <td className="text-center">

                                                                    {appeal.appealStatus === 0 &&
                                                                        <span className="badge badge rounded-pill badge-warning">{Enums.AppealStatus[appeal.appealStatus]}</span>
                                                                    }
                                                                    {appeal.appealStatus === 1 &&
                                                                        <span className="badge badge rounded-pill badge-success">{Enums.AppealStatus[appeal.appealStatus]}</span>
                                                                    }
                                                                    {appeal.appealStatus === 2 &&
                                                                        <span className="badge badge rounded-pill badge-danger">{Enums.AppealStatus[appeal.appealStatus]}</span>
                                                                    }
                                                                </td>
                                                                <td className="text-center">{moment(breach.timestamp).format("DD/MM/yyyy")} {moment(breach.timestamp).format("HH:mm")}</td>
                                                                <td className="text-center">
                                                                    <button className="btn btn-falcon-default btn-sm dropdown-toggle py-2 mr-3 dropdown-caret-none m-auto" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                                                    <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
                                                                        <button type="button" className="dropdown-item btn-sm" onClick={() => download(appeal.id)}><div className="icon-container"><i className="fas fa-arrow-down"></i></div> Download</button>
                                                                        <Link to={appealUrl} type="button" className="dropdown-item btn-sm"><div className="icon-container"><i className="fas fa-envelope"></i></div> View Details</Link>
                                                                        <button type="button" className="dropdown-item btn-sm" data-toggle="modal" data-target={`#delete-appeal-${appeal.id}`}><i className="fas fa-times"></i> Delete Appeal</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <div className="modal fade" id={`delete-appeal-${appeal.id}`} tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                                                <div className="modal-dialog" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this appeal?</h5>
                                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                        </div>
                                                                        <div className='modal-body'>
                                                                            <p>Deleting an appeal is permenant, and won't affect the breach status</p>
                                                                        </div>
                                                                        <div className="modal-footer">
                                                                            <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                                            <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal" onClick={() => deleteAppeal(appeal.id)}>
                                                                                Delete</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </tbody>
                                        }
                                    </table>
                                </div>
                            </div>

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-1 d-flex align-items-center pr-0">
                                            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Transfer of Liability</h5>
                                        </div>
                                        <div className="col-2 text-right pl-0 filter-buttons-wrapper">
                                            <button className="btn btn-success btn-sm ml-1" onClick={addNewTransfer}><i className="fas fa-plus"></i> Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top p-0 details-container">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead>
                                            <tr>
                                                <th>Notifier</th>
                                                <th>Liable Party</th>
                                                <th>Contact Type</th>
                                                <th>Contact Source</th>
                                                <th className="text-center">Date/Time</th>
                                                <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        {transfers !== null && transfers.notifier !== null && transfers.liabilityParty !== null &&
                                            <tbody>
                                                <tr>
                                                    <td><Link to={transferUrl}><h6 className="my-0">{transfers.notifier.firstName} {transfers.notifier.lastName}</h6></Link></td>
                                                    <td><Link to={transferUrl}><h6 className="my-0">{transfers.liabilityParty.firstName} {transfers.liabilityParty.lastName}</h6></Link></td>
                                                    <td>{Enums.ContactTypes[transfers.notifier.contactType]}</td>
                                                    <td>{Enums.ContactSource[transfers.notifier.contactSource]}</td>
                                                    <td className="text-center">{moment(transfers.notifier.timestamp).format("DD/MM/yyyy")} {moment(transfers.notifier.timestamp).format("HH:mm")}</td>
                                                    <td className="text-center">
                                                        <button className="btn btn-falcon-default btn-sm dropdown-toggle py-2 mr-3 dropdown-caret-none m-auto" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
                                                            <button type="button" className="dropdown-item btn-sm" onClick={() => download(transfers)}><div className="icon-container"><i className="fas fa-arrow-down"></i></div> Download</button>
                                                            <Link to={transferUrl} type="button" className="dropdown-item btn-sm"><div className="icon-container"><i className="fas fa-envelope"></i></div> View Details</Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>}
                                    </table>
                                </div>
                            </div>

                            <BreachPayments breachId={breach.id} organisationId={props.organisationId} token={props.token} onSavePayment={getBreach} />

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-1 d-flex align-items-center pr-0">
                                            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Ticket Details</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    <div className="row mb-2">
                                        <label class="col-sm-2 col-form-label col-form-label-sm">Number of Breaches</label>
                                        <div className="col-sm-10">
                                            <p class="form-control-plaintext form-control-sm outline-none" >{numberOfBreaches}</p>
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <label class="col-sm-2 col-form-label col-form-label-sm">Contravention</label>
                                        <div className=" col-sm-10">
                                            <select class="custom-select custom-select-sm" onChange={(e) => setContraventionId(e.target.value)}>
                                                {siteContraventions && siteContraventions !== null && siteContraventions.length > 0 &&
                                                    <>
                                                        {siteContraventions.map((siteContravention) => {
                                                            let contravention = null;
                                                            if (allContraventions && allContraventions !== null && allContraventions.length > 0) {
                                                                allContraventions.forEach(x => {
                                                                    if (siteContravention.contraventionId === x.id) {
                                                                        contravention = x;
                                                                    }
                                                                });
                                                            }
                                                            return <option value={contravention.id} selected={contravention.id === breach.contraventionId}>{contravention.name}</option>
                                                        })}
                                                    </>
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    {contraventionEdit &&
                                        <div className="row mb-3">
                                            <div className="offset-sm-2 col-auto">
                                                <button className="btn-sm btn btn-success" onClick={editBreach}><i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    }

                                    <div className="row mb-2">
                                        <label class="col-sm-2 col-form-label col-form-label-sm">Duplicate Ticket Check</label>
                                        <div className="col-sm-10">
                                            <p class="form-control-plaintext form-control-sm outline-none" >
                                                {dupeTicket === null &&
                                                    <>No Duplicates Found</>
                                                }
                                                {dupeTicket !== null &&
                                                    <Link>{dupeTicket.referenceNumber}</Link>
                                                }
                                            </p>
                                        </div>
                                    </div>

                                    {breach.parkingSession !== null && (durationMin !== 0 || durationSec !== 0 || durationHour !== 0) &&
                                        <div className="row mb-2">
                                            <label class="col-sm-2 col-form-label col-form-label-sm">Duration</label>
                                            <div className="col-sm-10">
                                                <p class="form-control-plaintext form-control-sm outline-none">
                                                    {durationHour > 0 && <>{durationHour} hours, </>} {durationMin} minutes, {durationSec} seconds
                                                </p>
                                            </div>
                                        </div>
                                    }

                                    <hr className="border-dashed border-bottom-0"></hr>

                                    {!arrayUtilities.isNullOrEmpty(allContraventions) && allContraventions.find(x => x.id === breach.contraventionId)?.contraventionType === 0 &&
                                        <div className="row">
                                            <div className="col-md-6 col-12 ">
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <p className="font-weight-semi-bold text-center mt-1 mb-2">Camera In</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 text-center">
                                                        <p className="font-weight-semi-bold ">Date</p>
                                                    </div>
                                                    <div className="col-6 text-center">{moment(breach.parkingSession.entry.timestamp).format("DD/MM/yyyy")}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 text-center">
                                                        <p className="font-weight-semi-bold ">Time</p>
                                                    </div>
                                                    <div className="col-6 text-center">{moment(breach.parkingSession.entry.timestamp).format("HH:mm")}</div>
                                                </div>
                                                <div className="row ticket-image-row">
                                                    {breach.parkingSession.entry.imageUrls.map((item) => {
                                                        if (item.toString().search("overview") !== -1) {
                                                            var now = moment.now();
                                                            var url = item + '?ts=' + moment(now).milliseconds();
                                                            return <RedactionPane {...props} onChange={reloadImages} id={breach.parkingSession.entry.id} url={url} />
                                                        } else {
                                                            return (
                                                                <div className="breach-image breach-number-plate">
                                                                    <img src={item} className="py-1" />
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 ">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p className="font-weight-semi-bold text-center mt-1 mb-2">Camera Out</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 text-center">
                                                        <p className="font-weight-semi-bold ">Date</p>
                                                    </div>
                                                    <div className="col-6 text-center">{moment(breach.parkingSession.exit.timestamp).format("DD/MM/yyyy")}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 text-center">
                                                        <p className="font-weight-semi-bold ">Time</p>
                                                    </div>
                                                    <div className="col-6 text-center">{moment(breach.parkingSession.exit.timestamp).format("HH:mm")}</div>
                                                </div>
                                                <div className="row ticket-image-row">
                                                    {breach.parkingSession.exit.imageUrls.map((item) => {
                                                        if (item.toString().search("overview") !== -1) {
                                                            var now = moment.now();
                                                            var url = item + '?ts=' + moment(now).milliseconds();
                                                            return <RedactionPane {...props} onChange={reloadImages} id={breach.parkingSession.exit.id} url={url} />
                                                        } else {
                                                            return (
                                                                <div className="breach-image breach-number-plate">
                                                                    <img src={item} className="py-1" />
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                        </div>}



                                    <div className="row pt-5 justify-content-center">
                                        <div className="col-6">
                                            <div className="row justify-content-center align-items-center">
                                                <div className="col-md-3 col-12 align-items-center d-flex justify-content-end">
                                                    <p className="font-weight-semi-bold mb-0 edit-vrm-label">VRM</p>
                                                </div>
                                                <div className=" col-md-7 col-12" >
                                                    {!isVrmInEdit && <>
                                                        <button className="button-no-style d-flex align-items-center number-plate-link" onClick={() => setIsVrmInEdit(true)}>{vrm === breach.vrm && <div className="number-plate">{breach.vrm}</div>}{vrm !== breach.vrm && <div className="number-plate">{breach.vrm}</div>} <i className="fas fa-edit fa-lg ml-2"></i></button>
                                                    </>}
                                                    {isVrmInEdit && <>
                                                        <input className="number-plate" autoFocus type="text" value={vrm} onChange={(e) => setVrm(e.target.value)} />
                                                        <button className="btn btn-success btn-sm ml-1" onClick={() => editBreach()}>Done</button>
                                                        <button className="btn btn-danger btn-sm ml-1" onClick={() => {
                                                            setIsVrmInEdit(false);
                                                            setVrm(breach.vrm);
                                                        }}>Cancel</button>
                                                    </>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!StringHelpers.isNullOrEmpty(vrm) && site && site !== null &&
                                <AllowedVrms vrm={vrm} siteId={site.id} organisationId={props.organisationId} token={props.token} />
                            }

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">
                                                <button className="button-no-style button-card-header collapsed" id="contact-details" type="button" data-toggle="collapse" data-target="#contact-details-list" aria-expanded="true" aria-controls="recent-visits-list">
                                                    Contact History <i className="fas fa-chevron-down"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div className="col-2 text-right pl-0 filter-buttons-wrapper">
                                            <Link className="btn btn-success btn-sm ml-1" to={newContactUrl}><i className="fas fa-plus"></i> Add</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container p-0 collapse" id="contact-details-list" aria-labelledby="Allow List" data-parent="#contact-details">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>Name</th>
                                                <th>Contact Type</th>
                                                <th>Contact Source</th>
                                                <th className="text-center">Status</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contactDetailsList !== null && contactDetailsList.map((item) => {
                                                var url = '/organisation/' + props.organisationId + '/breach/' + breachId + '/contact-details/' + item.id;
                                                return (
                                                    <>
                                                        <tr>
                                                            <td><Link className="button-no-style" to={url}><h6 className="mb-0">{item.firstName} {item.lastName}</h6></Link></td>
                                                            <td>{Enums.ContactTypes[item.contactType]}</td>
                                                            <td>{Enums.ContactSource[item.contactSource]}</td>
                                                            <td className="text-center">{item.isActive && <><i className="fas fa-check text-success"></i></>}{!item.isActive && <><i className="fas fa-times text-danger"></i></>}</td>
                                                            <td className="text-right">
                                                                <Link to={{
                                                                    pathname: url,
                                                                    state: {
                                                                        editMode: true
                                                                    }
                                                                }} className='btn btn-link'><i className="fas fa-edit text-secondary"></i></Link>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {!StringHelpers.isNullOrEmpty(vrm) && site && site !== null &&
                                <Payments vrm={vrm} breachDate={breach.timestamp} siteId={site.id} organisationId={props.organisationId} token={props.token} />
                            }

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">
                                                <button className="button-no-style button-card-header collapsed" id="recent-contras" type="button" data-toggle="collapse" data-target="#recent-contras-list" aria-expanded="true" aria-controls="recent-contras-list">
                                                    Previous Contraventions&nbsp;
                                                    <span className="translate-middle badge rounded-pill bg-danger">
                                                        {previousContraventions !== null && <>{previousContraventions.length}</>}
                                                    </span>
                                                    &nbsp;<i className="fas fa-chevron-down"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn btn-primary btn-sm ml-1" onClick={(e) => exportToCSV(previousContraventions.items)}>Export</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container p-0 collapse" id="recent-contras-list" aria-labelledby="Allow List" data-parent="#recent-contras">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>PCN</th>
                                                <th className="text-center">VRM</th>
                                                <th className="text-center">Contravention Date</th>
                                                <th>Contravention</th>
                                                <th>Site</th>
                                                <th>Status</th>
                                                <th>Outstanding Charges</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {previousContraventions && previousContraventions !== null && previousContraventions.length > 0 &&

                                                <>
                                                    {previousContraventions.map((item) => {
                                                        var url = "/organisation/" + props.organisationId + "/breach/" + item.id;
                                                        var balance = '£' + (Math.round(item.outstandingBalance * 100) / 100).toFixed(2);
                                                        return (
                                                            <tr>
                                                                <td><Link to={url}><h6 className="mb-0">{item.referenceNumber}{item.referenceNumber === null && <>Unknown</>}</h6></Link></td>
                                                                <td className="text-center"><h6 className="m-auto mb-0 small-number-plate">{item.vrm}</h6></td>
                                                                <td className="text-center">{item.timestamp !== null && <>{moment(item.timestamp).format("DD/MM/yyyy")}</>}</td>
                                                                <td>{item.contraventionName}</td>
                                                                <td>{item.siteName}</td>
                                                                <td>{Enums.BreachStatus[item.breachStatus]}</td>
                                                                <td className="text-right">{balance}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col-2">
                                        <h5 className="mb-0">
                                            <button className="button-no-style button-card-header collapsed" id="site" type="button" data-toggle="collapse" data-target="#site-details" aria-expanded="true" aria-controls="site-details">
                                                Site Details <i className="fas fa-chevron-down"></i>
                                            </button>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body bg-light border-top details-container collapse" id="site-details" aria-labelledby="Allow List" data-parent="#site">{breach !== null && <>{breach.siteName}</>}</div>
                        </div> */}

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-2">
                                            <h5 className="mb-0">
                                                <button className="button-no-style button-card-header collapsed" id="attachments" type="button" data-toggle="collapse" data-target="#attachments-list" aria-expanded="true" aria-controls="attachments">
                                                    Attachments &nbsp;
                                                    {attachments !== null &&
                                                        <span className="translate-middle badge rounded-pill bg-success">
                                                            <>{attachments.length}</>
                                                        </span>
                                                    }
                                                    &nbsp;<i className="fas fa-chevron-down"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div className="col-auto text-right pl-0 filter-buttons-wrapper">
                                            <button className="btn btn-success btn-sm ml-1" data-toggle="modal" data-target="#add-new-evidence" ><i className="fas fa-plus"></i> Add</button>
                                            <Link to={`/organisation/${props.organisationId}/breach/${breachId}/new-letter`} className="btn btn-primary btn-sm ml-1"><i className="fas fa-envelope mr-2"></i>New Letter</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container p-0 collapse" id="attachments-list" aria-labelledby="Allow List" data-parent="#attachments">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th className="thumbnail-col"></th>
                                                <th>File Name</th>
                                                <th className="text-center">Uploaded on</th>
                                                <th>Is Letter Image</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {attachments &&
                                                <>
                                                    {attachments.map((item) => {
                                                        var now = moment.now();
                                                        var url = item.url + '&ts=' + moment(now).milliseconds();
                                                        var id = "#attachemnt" + item.id;
                                                        return (
                                                            <tr>
                                                                <td className="thumbnail-col">{item.isImage &&
                                                                    <RedactionPane {...props} onChange={reloadImages} id={item.id} url={url} isAttachment={true} />
                                                                }
                                                                    {!item.isImage && <>
                                                                        {item.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                                        {item.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                                        {item.contentType !== "application/word.doc" && item.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                                    </>}
                                                                </td>
                                                                <td><a href={url} target="_blank"><h6 className="mb-0">{item.filename}</h6></a></td>
                                                                <td className="text-center">{item.timestamp !== null && <>{moment(item.timestamp).format("DD/MM/yyyy")}</>}</td>
                                                                <td>
                                                                    {item.isImage &&
                                                                        // TODO: This needs to be a self contained component, encapsulating the API call
                                                                        <input type="checkbox" checked={item.isLetterImage} onChange={(e) => attachmentIsLetterImage(item.id, e.target.checked)} />
                                                                    }
                                                                </td>
                                                                <td className='text-right'>
                                                                    <a href={url} target="_blank" className='btn btn-info btn-sm mr-3'><i className="fas fa-arrow-down"></i> Download</a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div className="modal fade save-dialog success-dialog" id="add-new-evidence" tabIndex="-1" role="dialog">
                                <UploadFile onSuccess={(file) => attachFile(file)} type="Attachments" organisationId={props.organisationId} />
                            </div>

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-2">
                                            <h5 className="mb-0">
                                                <button className="button-no-style button-card-header collapsed" id="note-button" type="button" data-toggle="collapse" data-target="#notes" aria-expanded="true" aria-controls="site-details">
                                                    Notes &nbsp;
                                                    {log !== null &&
                                                        <span className="translate-middle badge rounded-pill bg-success">
                                                            <>{log.length}</>
                                                        </span>
                                                    }
                                                    &nbsp;<i className="fas fa-chevron-down"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div className="col-2 text-right pl-0 filter-buttons-wrapper">
                                            <button className="btn btn-success btn-sm ml-1" data-toggle="modal" data-target="#note-modal" ><i className="fas fa-plus"></i> Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="note-modal" tabIndex="-1" role="dialog" aria-labelledby="On Hold Modal" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="deleteModalLabel">Add a note.</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-2">Note:</p>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mb-0">
                                                            <textarea className="form-control mb-0" rows={4} value={note} onChange={(e) => setNote(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" onClick={() => submitNote()}>
                                                    Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container collapse p-0" id="notes" aria-labelledby="Allow List" data-parent="#note-button">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>User</th>
                                                <th>Note</th>
                                                <th className="text-center">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {log !== null && log.map((item) => {
                                                var statusChange = false;
                                                if (item.oldBreachStatus !== item.newBreachStatus) {
                                                    statusChange = true;
                                                }
                                                var onHoldChange = false;
                                                if (item.wasOnHold !== item.isOnHold) {
                                                    onHoldChange = true;
                                                }
                                                return (
                                                    <>
                                                        <tr className="align-items-center">
                                                            <td><button className="button-no-style pt-1 mt-1"><h6 className="pt-1">{item.userName !== null && <>{item.userName}</>}{item.userName === null && <>Unknown</>}</h6></button></td>
                                                            <td>

                                                                {statusChange &&
                                                                    <div>
                                                                        {Enums.BreachStatus[item.oldBreachStatus]} <i className="fas fa-arrow-right"></i> {Enums.BreachStatus[item.newBreachStatus]}
                                                                    </div>
                                                                }
                                                                {!StringHelpers.isNullOrEmpty(item.note) &&
                                                                    <div>
                                                                        {item.note}
                                                                    </div>
                                                                }
                                                                {onHoldChange && <>

                                                                    {item.isOnHold &&
                                                                        <div>
                                                                            Placed on hold
                                                                        </div>
                                                                    }
                                                                    {!item.isOnHold &&
                                                                        <div>
                                                                            Taken off hold
                                                                        </div>
                                                                    }
                                                                </>}
                                                            </td>
                                                            <td className="text-center">{moment(item.timestamp).format("DD/MM/yyyy")} {moment(item.timestamp).format("HH:mm")}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default Breach;

