export const ConfigurationUpdate = (props) => {
	return (
		<div className="card mb-3">
			<div className="card-header">
				<div className="row align-items-center justify-content-between">
					<div className="col-3">
						<h5 className="mb-0">
							<button
								className="button-card-header button-no-style collapsed"
								id={props.id}
								type="button"
								data-toggle="collapse"
								data-target={`#${props.dataTarget}`}
								aria-expanded="true"
								aria-controls={props.dataTarget}>
								{props.title} <i className="fas fa-chevron-down"></i>
							</button>
						</h5>
					</div>
					<div className="col-auto">
						<button
							type="button"
							className="btn btn-success btn-sm ml-2"
							onClick={() => props.handleHeaderAction()}>
							<i className="fas fa-plus"></i> Add
						</button>
					</div>
				</div>
			</div>

			<div
				className="card-body bg-light border-top details-container collapse"
				id={props.dataTarget}
				aria-labelledby={props.ariaLabel}
				data-parent={`#${props.id}`}>
				{props.children}
			</div>
		</div>
	);
};
