import { useEffect, useState, useRef } from "react"
import api from "../api/api";
import { DataTable } from "../shared/dataTable";
import Loader from "../shared/loader";
import Pagination from "../shared/pagination";

export const PaymentsDataEnum = {
    "logo": "",
    "vrm": "VRM",
    "siteName": "Site Name",
    "start": "Start",
    "end": "End",
    "amount": "Amount"
}

export const PaymentsDataEnumWithSimilarity = {
    "logo": "",
    "vrm": "VRM",
    "similarity": "Similarity",
    "siteName": "Site Name",
    "start": "Start",
    "end": "End",
    "amount": "Amount"
}

export const paymentProviderLogoBasePath = "/assets/img/logos/payment-providers/";

export const PaymentProviderLogoEnum = {
    0: "parkfolio-logo.png", 
    1: "parkonomy-logo.png",
    2: "ringo-logo.png", 
    3: "flowbird-logo.png", 
    4: "justpark-logo.png", 
    5: "tap2park-logo.png", 
    6: "paybyphone-logo.svg"
}

export default function PaymentsData(props) {
    const [data, setData] = useState(null);

    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [lastPage, setLastPage] = useState(false);
    const [similarityThreshold, setSimilarityThreshold] = useState(65);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [site, setSite] = useState(null);

    const fetchPaymentsData = (page, similarityThreshold, searchQuery) => {
        setIsLoading(true);
        api.getPaymentsData(
            data => { setData(data); setIsLoading(false) },
            () => setIsLoading(false),
            searchQuery, site?.id, null, null, parseInt(page), parseFloat(similarityThreshold / 100), props.organisationId, props.token
        )
    }
    useEffect(() => {
        fetchPaymentsData(0, 65, "");
    }, []);

    useEffect(() => {
        fetchPaymentsData(pageNumber, similarityThreshold, searchQuery);
    }, [pageNumber, site?.id]);

    const changepageNumber = (num) => {
        setPageNumber(num);
    };
    const isLastPage = () => {
        if (data && data !== undefined) {
            setLastPage(false)
            setPageCount(Math.ceil(data.totalCount / 100));
            if (pageNumber + 1 >= Math.ceil(data.totalCount / 100)) {
                setLastPage(true)
            }
        }
    };

    const clearFilters = () => {
        setSearchQuery("");
        setSimilarityThreshold(65);
        setSite(null)
        setPageNumber(0);
        fetchPaymentsData(0, 65, "");
    }

    useEffect(() => isLastPage(), [data]);

    const fetchSiteUrl = (id) => {
        return `/organisation/${props.organisationId}/client/${props.sites.find(site => site.id === id)?.clientId}/site/${id}`;
    }

    const changeSimilarityThresholdTimeout = useRef();
    const changeSimilarityThreshold = function (x) {

        setSimilarityThreshold(Number(x));

        if (changeSimilarityThresholdTimeout.current) {
            clearTimeout(changeSimilarityThresholdTimeout.current);
            changeSimilarityThresholdTimeout.current = null;
        }

        changeSimilarityThresholdTimeout.current = setTimeout(() => {
            fetchPaymentsData(pageNumber, Number(x), searchQuery);
        }, 1000);
    };

    const changeSearchQueryTimeout = useRef();
    const changeSearchQuery = function(query) {
        setSearchQuery(query);

        if (changeSearchQueryTimeout.current) {
            clearTimeout(changeSearchQueryTimeout.current);
            changeSearchQueryTimeout.current = null;
        }

        changeSearchQueryTimeout.current = setTimeout(() => {
            fetchPaymentsData(pageNumber, similarityThreshold, query)
        }, 1000)
        
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-header raw-data-card-header">
                            <h5 className="mb-0 d-flex align-items-center">
                                Payments
                            </h5>
                            <div className="payments-data-filters-container">
                                <div className="d-flex">
                                    <input
                                        className="form-control kadoe-search-bar"
                                        value={ searchQuery }
                                        type="text"
                                        autoFocus={ true }
                                        placeholder="search..."
                                        onChange={ e => changeSearchQuery(e.target.value) }
                                    />
                                    <button
                                        className="btn btn-falcon-default btn-sm py-0 d-flex align-items-center"
                                        onClick={ clearFilters }><i className="fas fa-times mr-2"></i> Clear
                                    </button>
                                </div>
                                <div className='d-flex align-items-center mr-3'>
                                    <label className='m-0 mr-3'>Similarity: { similarityThreshold }%</label>
                                    <input
                                        class="form-range"
                                        type="range"
                                        min="0"
                                        max="100"
                                        value={ similarityThreshold }
                                        onChange={ (e) => changeSimilarityThreshold(e.target.value) }
                                    />
                                </div>

                                <div className="d-flex align-items-center" id="select-site">
                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{ site && <>{ site.name.substring(0, 8) }</> }{ !site && <>Filter by Site</> }</button>

                                    <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-site" >
                                        <div className="bg-white py-3 rounded-soft">
                                            <button
                                                className="dropdown-item text-base px-3 py-2 edit-dropdown"
                                                onClick={ () => setSite(null) }>
                                                All
                                            </button>
                                            {props.sites.map((currentSite) => {
                                                return (
                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setSite(currentSite)}>{currentSite.name}</button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <button
                                        className="btn btn-primary btn-sm mr-1 ml-1 nowrap"
                                        onClick={ () => fetchPaymentsData(pageNumber, similarityThreshold, searchQuery) }>
                                        <i className="fas fa-redo"></i> Refresh
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    { isLoading
                        ? <Loader />
                        : <>
                            {
                                data && data.items && data.items.length > 0 &&
                                <DataTable
                                    enum={ data.items[0].similarity ? PaymentsDataEnumWithSimilarity : PaymentsDataEnum }
                                    tableData={ 
                                        data.items
                                        .map(item => (
                                            { 
                                                ...item, 
                                                logo: `${paymentProviderLogoBasePath}${PaymentProviderLogoEnum[item.provider]}`,
                                                siteUrl: fetchSiteUrl(item.siteId)
                                            })
                                        ) 
                                    }
                                />
                            }
                        </>
                    }
                    <div className="d-flex justify-content-center row align-items-center pt-2">
                        <div className="col-auto align-items-center">
                            <Pagination totalResults={ data?.totalCount } currentPage={ pageNumber } changePageNumber={ changepageNumber } />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}