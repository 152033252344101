import { useState, useRef } from "react"

export const NewLetterEditTemplateItem = ({ item, handleValueUpdate, isSendingLetter }) => {
    const [templateItemValue, setTemplateItemValue] = useState(item.content ?? "");

    const changeTextFieldValue = useRef();
    const handleChange = function (value) {
        
        setTemplateItemValue(value);

        if (changeTextFieldValue.current) {
            clearTimeout(changeTextFieldValue.current);
            changeTextFieldValue.current = null;
        }

        changeTextFieldValue.current = setTimeout(() => {
            handleValueUpdate(value);
        }, 2000);
    };

    return (
        <>
            <label class="col-sm-4 col-form-label col-form-label-sm">
                { item.name }
            </label>
            <div className="col-sm-8">
                <div className="form-group m-0 w-100 mr-3">
                    { 
                        item.wrapText 
                        ? <textarea disabled={isSendingLetter} rows={10} onChange={e => handleChange(e.target.value)} className="form-control mb-0" type="text" value={ templateItemValue } /> 
                        : <input disabled={isSendingLetter} onChange={e => handleChange(e.target.value)} className="form-control mb-0" type="text" value={ templateItemValue } />
                    }
                </div>
            </div>
        </>
    )
}