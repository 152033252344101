import Enums from "../../utilities/enum";
import {useSortable} from "@dnd-kit/sortable"
import {CSS} from "@dnd-kit/utilities";

export const KioskTimeOption = (props) => {
	const modeStringValue = Enums.TimeSpanMode[String(props.option.type)];
	const timeValue = props.option.duration;
	const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: props.id});
    const style = {
		transition,
		transform: CSS.Transform.toString(transform)
	}
	const listenersOnState = props.editMode ? {...listeners} : undefined
	return (
		<tr style={style} ref={setNodeRef} {...attributes} {...listenersOnState} className="align-items-center">
			<td className="m-0 pl-0">
				{timeValue && <>{timeValue}&nbsp;</>}
				{
				!timeValue || timeValue > 1
				? modeStringValue
				: modeStringValue.substring(0, modeStringValue.length - 1)}
			</td>
			<td className="time-span-action-buttons d-flex align-items-center justify-content-end">
				<div
					onClick={() =>
						{	
							props.editMode && props.handleUpdateModal(
								props.id,
								props.option.duration,
								props.option.type
							)
						}
					}
					data-toggle="modal"
					data-target="#update-time-span-modal"
					role="button"
					className="mr-3">
					<i class={`far fa-edit ${!props.editMode ? "text-muted cursor-disabled" : ""}`}></i>
				</div>
				<div
					onClick={() =>
						props.editMode && props.handleUpdateModal(
							props.id,
							props.option.duration,
							props.option.type
						)
					}
					data-toggle="modal"
					data-target="#delete-time-span-modal"
					role="button">
					<i class={`fas fa-trash-alt ${!props.editMode ? "text-muted cursor-disabled" : ""}`}></i>
				</div>
			</td>
		</tr>
	);
};
