export default {
    ClientTypes: { 0: "Managing Agent", 1: "Landowner" },
    ContraventionTypes: { 0: "ANPR", 1: "MNPR" },
    BreachStatus: {
        0: "New",
        // DVLA
        100: "DVLA Requested", 101: "DVLA Received", 102: "DVLA No Trace", 103: "DVLA Error", 104: "DVLA Mismatch", 105: "DVLA Hard Copy To Follow",
        // Internal Appeals
        200: "Appeal Received", 201: "Appeal Accepted", 202: "Appeal Declined",
        // Transfer of Liability
        210: "Transfer Of Liability Received", 211: "Transfer Of Liability Accepted", 212: "Transfer Of Liability Declined",
        // External Appeals
        220: "With Appeals Service", 221: "Appeals Service Approved", 222: "Appeals Service Declined",
        // Notices
        300: "Ready To Send Letters", 301: "Sent Notice To Keeper", 302: "Sent POFA Notice To Keeper", 303: "Sent Non-POFA Notice To Keeper", 304: "Sent Reminder Notice", 305: "Sent Notice To Hirer", 306: "Sent Notice To Driver", 307: "Sent Final Demand", 308: "Sent Appeal Declined Letter", 309: "Sent Cancellation Letter",
        // DR & Litigation
        400: "Ready For Debt Recovery", 401: "Ready For Litigation", 402: "Send To Debt Recovery", 403: "Sent To Litigation", 404: "With Debt Recovery",
        // Final Steps
        500: "Paid", 501: "Cancelled", 502: "Completed", 503: "Voided"
    },
    UserRoles: { 0: "Organisation Admin", 1: "Organisation Manager", 2: "Organisation Processor", 3: "Organisation Sales Agent", 4: "Client Manager", 5: "Client", 6: "Warden", 7: "Installer" },
    InviteStatus: { 0: "Pending", 1: "Accepted", 2: "Expired" },
    ContactTypes: { 0: "Keeper", 1: "Hirer", 2: "Driver" },
    ContactSource: { 0: "DVLA", 1: "Appeal", 2: "Liability Notifier", 3: "Liability Party", 4: "Debt Recovery", 5: "Other" },
    LetterTemplateItemType: {0: "Text", 1: "Image"},
    ChargeType: {"0": "Amount", "1": "Percentage"},
    CommissionStructure: {"0": "Fixed Rate", "1": "Percentage"},
    PaymentProvider: {0: "Stripe", 1: "Manual"},
    AppealStatus: { 0: "Received", 1: "Accepted", 2: "Declined" },
    DeviceType: {0: "Camera", 1: "Kiosk"},
    OutputFormat: {0: "JSON", 1: "CSV"},
    TimeSpanMode: {0: "Minutes", 1: "Hours", 2: "Days", 3: "End of Day"},
    PaymentType: {0: "Parkfolio", 1: "Parkonomy", 2: "RingGo", 3: "Flowbird", 4: "JustPark", 5: "Tap2Park", 6: "PayByPhone"},
}