import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Loader from '../shared/loader';
import { SketchPicker } from 'react-color';
import Enums from '../../utilities/enum';
import UploadFile from '../shared/file-uploader';

function OrganisationSettings(props) {
    const [settings, setSettings] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const [orgUrl, setOrgUrl] = useState("");

    const [iasInEdit, setIasInEdit] = useState(false);
    const [iasCanSave, setIasCanSave] = useState(false);
    const [iasPublicHash, setIasPublicHash] = useState(null);
    const [iasPrivateHash, setIasPrivateHash] = useState(null);

    const [parkonomyInEdit, setParkonomyInEdit] = useState(false);
    const [parkonomyApiKey, setParkonomyApiKey] = useState("");

    const [ringGoInEdit, setRingGoInEdit] = useState(false);
    const [ringGoApiPublicKey, setRingGoApiPublicKey] = useState("");
    const [ringGoApiPrivateKey, setRingGoApiPrivateKey] = useState("");

    const [paymentProvider, setPaymentProvider] = useState(null);
    const [paymentProviders, setPaymentProviders] = useState(null);
    const [stripePublishableKey, setStripePublishableKey] = useState("");
    const [stripeSecretKey, setStripeSecretKey] = useState("");
    const [paymentProviderInEdit, setPaymentProviderInEdit] = useState(false);

    const [theme, setTheme] = useState(null);
    const [titleColor, setTitleColor] = useState('111111');
    const [titleBackgroundColor, setTitleBackgroundColor] = useState('FFFFFF');
    const [textColor, setTextColor] = useState('111111');
    const [backgroundColor, setBackgroundColor] = useState('FFFFFF');

    const [titleColorStyle, setTitleColorStyle] = useState(null);
    const [titleBackgroundColorStyle, setTitleBackgroundColorStyle] = useState(null);
    const [textColorStyle, setTextColorStyle] = useState(null);
    const [customCss, setCustomCss] = useState("");
    const [hostName, setHostName] = useState("");
    const [managePcnLogoAttachmentId, setManagePcnLogoAttachmentId] = useState(null);
    const [managePcnLogoAttachment, setManagePcnLogoAttachment] = useState(null);
    const [submitMessage, setSubmitMessage] = useState("");

    const [kadoeInEdit, setKadoeInEdit] = useState(false);
    const [kadoeApplicationId, setKadoeApplicationId] = useState("");
    const [kadoeEnquirerId, setKadoeEnquirerId] = useState("");
    const [kadoeIntermediaryId, setKadoeIntermediaryId] = useState("");

    const [flowbirdInEdit, setFlowbirdInEdit] = useState(false);
    const [flowbirdApiUsername, setFlowbirdApiUsername] = useState("");
    const [flowbirdApiPassword, setFlowbirdApiPassword] = useState("");

    const [payByPhoneInEdit, setPayByPhoneInEdit] = useState(false);
    const [payByPhoneUsername, setPayByPhoneUsername] = useState("");
    const [payByPhonePassword, setPayByPhonePassword] = useState("");

    const [justParkInEdit, setJustParkInEdit] = useState(false);
    const [justParkApiKey, setJustParkApiKey] = useState("");

    const [tap2ParkInEdit, setTap2ParkInEdit] = useState(false);
    const [tap2ParkApiKey, setTap2ParkApiKey] = useState("");

    const getOrgSettings = function () {
        Api.getOrganisationSettings(
            (data) => setSettings(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    const edit = function () {
        Api.editOrganisationSettings(
            (data) => {
                setParkonomyInEdit(false);
                setRingGoInEdit(false);
                setIasInEdit(false);
                setPaymentProviderInEdit(false);
                setKadoeInEdit(false);
                setJustParkInEdit(false);
                setTap2ParkInEdit(false);
                setPayByPhoneInEdit(false);
                setSettings(data);
                setSubmitMessage("Successful Edit.")
            },
            (error) => setErrorMessage(error),
            parkonomyApiKey, iasPrivateHash, iasPublicHash, paymentProvider, stripePublishableKey, stripeSecretKey,
            titleColor, textColor, backgroundColor, titleBackgroundColor, customCss, managePcnLogoAttachmentId, hostName, kadoeApplicationId, kadoeEnquirerId, kadoeIntermediaryId,
            ringGoApiPrivateKey, ringGoApiPublicKey, flowbirdApiUsername, flowbirdApiPassword,
            justParkApiKey, tap2ParkApiKey, payByPhoneUsername, payByPhonePassword,
            props.organisationId,
            props.token
        );
    };

    const handleTitleColorChange = function (color) {
        setTitleColor(color.hex.substring(1, 7));
    };

    const handleTitleBackgroundColorChange = function (color) {
        setTitleBackgroundColor(color.hex.substring(1, 7));
    };

    const handleTextColorChange = function (color) {
        setTextColor(color.hex.substring(1, 7));
    };

    const handleBackgroundColorChange = function (color) {
        setBackgroundColor(color.hex.substring(1, 7));
    };

    const getPaymentProviders = function () {
        var temp = [];
        for (let index = 0; index => 0; index++) {
            if (Enums.PaymentProvider[index] !== undefined) {
                temp.push(index);
            } else {
                break;
            }
        }
        setPaymentProviders(temp);
    };


    useEffect(() => {
        if (settings !== null) {
            setParkonomyApiKey(settings.parkonomyApiKey)
            setRingGoApiPrivateKey(settings.ringGoApiPrivateKey);
            setRingGoApiPublicKey(settings.ringGoApiPublicKey);
            setIasPrivateHash(settings.iasPrivateHash)
            setIasPublicHash(settings.iasPublicHash)
            setPaymentProvider(settings.paymentProvider)
            setStripeSecretKey(settings.stripeSecretKey)
            setStripePublishableKey(settings.stripePublishableKey)
            if (settings.managePcnTextColour !== null)
                setTextColor(settings.managePcnTextColour);
            if (settings.managePcnTitleColour !== null)
                setTitleColor(settings.managePcnTitleColour);
            if (settings.managePcnTitleBackgroundColour !== null)
                setTitleBackgroundColor(settings.managePcnTitleBackgroundColour);
            if (settings.managePcnBackgroundColour !== null)
                setBackgroundColor(settings.managePcnBackgroundColour);
            setCustomCss(settings.managePcnCustomCss);
            if (settings.managePcnLogoAttachment !== null && settings.managePcnLogoAttachment !== undefined)
                setManagePcnLogoAttachmentId(settings.managePcnLogoAttachment.id);
            setManagePcnLogoAttachment(settings.managePcnLogoAttachment);
            setHostName(settings.managePcnHostName);
            setKadoeIntermediaryId(settings.kadoeIntermediaryId);
            setKadoeEnquirerId(settings.kadoeEnquirerId);
            setKadoeApplicationId(settings.kadoeApplicationId);
            setFlowbirdApiUsername(settings.flowbirdApiUsername);
            setFlowbirdApiPassword(settings.flowbirdApiPassword);
            setJustParkApiKey(settings.justParkApiKey);
            setTap2ParkApiKey(settings.tap2ParkApiKey);
            setPayByPhoneUsername(settings.payByPhoneUsername);
            setPayByPhonePassword(settings.payByPhonePassword);
        }
    }, [settings]);

    useEffect(() => {
        if (iasPublicHash !== null && iasPublicHash !== "" && iasPrivateHash !== null && iasPrivateHash !== "") {
            setIasCanSave(true);
        } else {
            setIasCanSave(false);
        }
    }, [iasPrivateHash, iasPublicHash]);

    useEffect(() => {
        getOrgSettings();
        getPaymentProviders();
        setOrgUrl("/organisation/" + props.organisationId)
        setStyles();
    }, []);

    const resetStyles = function () {
        setTextColor('111111');
        setTitleColor('111111');
        setTitleBackgroundColor('FFFFFF');
        setBackgroundColor('FFFFFF');
        setCustomCss("");
    };

    const setStyles = function () {
        const style1 = {
            color: '#' + titleColor,
        }
        setTitleColorStyle(style1);
        const style2 = {
            color: '#' + textColor,
            backgroundColor: '#' + backgroundColor,
        }
        setTextColorStyle(style2);
        const style4 = {
            backgroundColor: '#' + titleBackgroundColor,
        }
        setTitleBackgroundColorStyle(style4);

        let selector = document.getElementById('login-card');
        var style = document.createElement('style');
        style.innerHTML = customCss;
        if(selector!== null){
            selector.appendChild(style);
        }
    };

    useEffect(() => {
        setStyles();
    }, [titleColor, titleBackgroundColor, textColor, backgroundColor, customCss]);

    return (
        <>
            {settings === null && <Loader />}{settings && settings !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Organisation Settings</h5>
                                        </div>
                                        <div className="col-auto">
                                            <Link className="btn btn-falcon-default btn-sm mr-1 py-1" type="button" to={orgUrl}><i className="fas fa-reply"></i> Back</Link>
                                        </div>
                                    </div>
                                </div>
                                {errorMessage !== "" &&
                                    <div className="card-body bg-light border-top details-container">
                                        <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>
                                    </div>}
                                {submitMessage !== "" &&
                                    <div className="card-body bg-light border-top details-container">
                                        <div className="row justify-content-center">
                                            <div className="col-auto">
                                                <h5 className="text-success">
                                                    {submitMessage}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Theme Settings</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container color-picker-container">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row mb-1 align-items-center">
                                                <div className="col-5">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">PCN Host Name</p>
                                                </div>
                                                <div className="col-7">
                                                    <div className="form-group">
                                                        <input value={hostName} className="form-control" type="text" onChange={(e) => setHostName(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-1 align-items-center">
                                                <div className="col-5">
                                                    <p className="font-weight-semi-bold mb-0">Title Colour</p>
                                                </div>
                                                <div className="col-6">
                                                    <button className="button-no-style" data-toggle="modal" data-target="#title-color-picker" id="title-color">
                                                        <div className="color-code-row">#{titleColor}<div className="tag-colour-disc ml-2" style={{ backgroundColor: titleColor }}></div></div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mb-1 align-items-center">
                                                <div className="col-5">
                                                    <p className="font-weight-semi-bold mb-0">Title Background Colour</p>
                                                </div>
                                                <div className="col-6">
                                                    <button className="button-no-style" id="title-back-color" data-toggle="modal" data-target="#title-back-color-picker">
                                                        <div className="color-code-row">#{titleBackgroundColor}<div className="tag-colour-disc ml-2" style={{ backgroundColor: titleBackgroundColor }}></div></div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mb-1 align-items-center">
                                                <div className="col-5">
                                                    <p className="font-weight-semi-bold mb-0">Main Background Colour</p>
                                                </div>
                                                <div className="col-6">
                                                    <button className="button-no-style" id="background-color" data-toggle="modal" data-target="#background-color-picker">
                                                        <div className="color-code-row">#{backgroundColor}<div className="tag-colour-disc ml-2" style={{ backgroundColor: backgroundColor }}></div></div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mb-1 align-items-center">
                                                <div className="col-5">
                                                    <p className="font-weight-semi-bold mb-0">Text Colour</p>
                                                </div>
                                                <div className="col-6">
                                                    <button className="button-no-style" data-toggle="modal" data-target="#text-color-picker" id="text-color">
                                                        <div className="color-code-row">#{textColor}<div className="tag-colour-disc ml-2" style={{ backgroundColor: textColor }}></div></div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mb-1 align-items-center">
                                                <div className="col-5">
                                                    <p className="font-weight-semi-bold mb-1">Add more custom CSS</p>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group mb-1">
                                                        <textarea className="form-control mb-0" rows={4} type="text" value={customCss} onChange={(e) => setCustomCss(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center align-items-center">
                                                <div className="col-auto">
                                                    <button className="btn btn-falcon-default mt-2" data-toggle="modal" data-target="#add-new-evidence">Select Logo</button>
                                                </div>
                                            </div>
                                            <div className="modal fade save-dialog success-dialog" id="add-new-evidence" tabIndex="-1" role="dialog">
                                                <UploadFile onSuccess={(file) => setManagePcnLogoAttachmentId(file.id)} type="Logo" organisationId={props.organisationId} />
                                            </div>
                                            <div className="row mb-1 align-items-center">
                                                <div className="col-auto">
                                                    <button className="btn btn-sm btn-falcon-default mx-1" onClick={() => resetStyles()}><i className="fas fa-times"></i> Reset</button>
                                                    <button className="btn btn-sm btn-success mx-1" onClick={() => edit()}><i className="fas fa-check"></i> Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 bg-white">
                                            {managePcnLogoAttachment !== null && managePcnLogoAttachment !== undefined &&
                                                <div className="row justify-content-center">
                                                    <div className="col-auto">
                                                        <div className="org-logo-container mt-3">
                                                            <img src={managePcnLogoAttachment.url} />
                                                        </div>
                                                    </div>
                                                </div>}
                                            <div className="row align-items-center justify-content-center landing-page-container">
                                                <div id="login-card" className="card my-3">
                                                    <div className="card-header" style={titleBackgroundColorStyle}>
                                                        <div className="row align-items-center justify-content-center">
                                                            <h3 className="mb-0" style={titleColorStyle}>Manage your PCN</h3>
                                                        </div>
                                                        <div className="row align-items-center justify-content-center">
                                                            <h5 className="mt-1" style={titleColorStyle}>Please provide details below to start your process</h5>
                                                        </div>
                                                    </div>
                                                    <div className="card-body" style={textColorStyle}>
                                                        <div className="row">
                                                            <div className="col-12 col-md-6">
                                                                <p className="font-weight-semi-bold text-right mt-1 mb-1">Parking Charge Number (PCN)*</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" placeholder="AA12345" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-md-6">
                                                                <p className="font-weight-semi-bold text-right mt-1 mb-1">Vehicle Registration Mark (VRM)*</p>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <div className="form-group mb-1">
                                                                    <input className="form-control mb-0" type="text" placeholder="GF45ASF" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer" style={titleBackgroundColorStyle}>
                                                        <div className="row client-edit-buttons">
                                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                                <div id="dashboard-actions">
                                                                    <button type="button" disabled={true} className="btn btn-falcon-default btn-sm ml-2">
                                                                        <i className="fas fa-times"></i> Clear</button>
                                                                    <button type="button" disabled={true} className="btn btn-success btn-sm ml-2">
                                                                        <i className="fas fa-check"></i> Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade color-picker-modal" id="title-color-picker" data-parent="#title-color" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="deleteModalLabel">Select Colour</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <SketchPicker color={'#' + titleColor} onChange={handleTitleColorChange} />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button className="btn btn-sm btn-falcon-default" data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade color-picker-modal" id="title-back-color-picker" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="deleteModalLabel">Select Colour</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <SketchPicker color={'#' + titleBackgroundColor} onChange={handleTitleBackgroundColorChange} />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button className="btn btn-sm btn-falcon-default" data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade color-picker-modal" id="background-color-picker" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="deleteModalLabel">Select Colour</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <SketchPicker color={'#' + backgroundColor} onChange={handleBackgroundColorChange} />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button className="btn btn-sm btn-falcon-default" data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade color-picker-modal" id="text-color-picker" data-parent="#text-color" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="deleteModalLabel">Select Colour</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <SketchPicker color={'#' + textColor} onChange={handleTextColorChange} />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button className="btn btn-sm btn-falcon-default" data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Parkonomy Settings</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setParkonomyInEdit(!parkonomyInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    {!parkonomyInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Parkonomy API Key</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{parkonomyApiKey}</div>
                                        </div>
                                    </>}
                                    {parkonomyInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Parkonomy API Key</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={parkonomyApiKey} onChange={(e) => setParkonomyApiKey(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                                {parkonomyInEdit && <>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setParkonomyInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">RingGo Settings</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setRingGoInEdit(!ringGoInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">RingGo API Private Key</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            {!ringGoInEdit &&                             
                                                <>{ringGoApiPrivateKey}</>
                                            }
                                            {ringGoInEdit &&
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={ringGoApiPrivateKey} onChange={(e) => setRingGoApiPrivateKey(e.target.value)} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">RingGo API Public Key</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            {!ringGoInEdit &&                             
                                                <>{ringGoApiPublicKey}</>
                                            }
                                            {ringGoInEdit &&
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={ringGoApiPublicKey} onChange={(e) => setRingGoApiPublicKey(e.target.value)} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {ringGoInEdit && <>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setRingGoInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">IAS Settings</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIasInEdit(!iasInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    {!iasInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">IAS Public Hash</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{iasPublicHash}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">IAS Private Hash</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{iasPrivateHash}</div>
                                        </div>
                                    </>}
                                    {iasInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">IAS Public Hash</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={iasPublicHash} onChange={(e) => setIasPublicHash(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">IAS Private Hash</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={iasPrivateHash} onChange={(e) => setIasPrivateHash(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                                {iasInEdit && <>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setIasInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={!iasCanSave} onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Payment Provider Settings</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setPaymentProviderInEdit(!paymentProviderInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    {!paymentProviderInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Payment Provider</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{Enums.PaymentProvider[paymentProvider]}</div>
                                        </div>
                                        {paymentProvider === 0 && <>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Stripe Publishable Key</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{stripePublishableKey}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Stripe Secret Key</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{stripeSecretKey}</div>
                                            </div>
                                        </>}
                                    </>}
                                    {paymentProviderInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Payment Provider</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <div className="dropdown ">
                                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-0 edit-dropdown" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{paymentProvider !== null && <>{Enums.PaymentProvider[paymentProvider]}</>}{paymentProvider === null && <>Select Payment Provider</>}</button>
                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-type" >
                                                            <div className="bg-white py-2 rounded-soft">
                                                                {paymentProviders !== null && paymentProviders.map((item, i = 0) => {
                                                                    i++;
                                                                    return (
                                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setPaymentProvider(i - 1)}>{Enums.PaymentProvider[item]}</button>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {paymentProvider === 0 && <>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mb-1">Stripe Publishable Key</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-0">
                                                        <input className="form-control mb-1" type="text" value={stripePublishableKey} onChange={(e) => setStripePublishableKey(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mb-1">Stripe Secret Key</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-0">
                                                        <input className="form-control mb-1" type="text" value={stripeSecretKey} onChange={(e) => setStripeSecretKey(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                    </>}
                                </div>
                                {paymentProviderInEdit && <>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setPaymentProviderInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">KADOE Settings</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setKadoeInEdit(!kadoeInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    {!kadoeInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">KADOE Application ID</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{kadoeApplicationId}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">KADOE Enquirer ID</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{kadoeEnquirerId}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">KADOE Intermediary ID</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{kadoeIntermediaryId}</div>
                                        </div>
                                    </>}
                                    {kadoeInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">KADOE Application ID</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={kadoeApplicationId} onChange={(e) => setKadoeApplicationId(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">KADOE Enquirer ID</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={kadoeEnquirerId} onChange={(e) => setKadoeEnquirerId(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">KADOE Intermediary ID</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={kadoeIntermediaryId} onChange={(e) => setKadoeIntermediaryId(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">KADOE Requests Path</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{settings.kadoeRequestsPath}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">KADOE Responses Path</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{settings.kadoeResponsesPath}</div>
                                    </div>
                                </div>
                                {kadoeInEdit && <>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setIasInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={!iasCanSave} onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>

                            
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Flowbird Settings</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setFlowbirdInEdit(!flowbirdInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    {!flowbirdInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Flowbird API Username</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{flowbirdApiUsername}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Flowbird API Password</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{flowbirdApiPassword}</div>
                                        </div>
                                    </>}
                                    {flowbirdInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Flowbird API Username</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={flowbirdApiUsername} onChange={(e) => setFlowbirdApiUsername(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Flowbird API Password</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={flowbirdApiPassword} onChange={(e) => setFlowbirdApiPassword(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                                {flowbirdInEdit && <>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setFlowbirdInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">JustPark Settings</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setJustParkInEdit(!justParkInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    {!justParkInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">JustPark API Key</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{justParkApiKey}</div>
                                        </div>
                                    </>}
                                    {justParkInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">JustPark API Key</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={justParkApiKey} onChange={(e) => setJustParkApiKey(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                                {justParkInEdit && <>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setJustParkInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Tap2Park Settings</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setTap2ParkInEdit(!tap2ParkInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    {!tap2ParkInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Tap2Park API Key</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{tap2ParkApiKey}</div>
                                        </div>
                                        
                                    </>}
                                    {tap2ParkInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Tap2Park API Key</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={tap2ParkApiKey} onChange={(e) => setTap2ParkApiKey(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </>}
                                </div>
                                {tap2ParkInEdit && <>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setTap2ParkInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">PayByPhone Settings</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setPayByPhoneInEdit(!payByPhoneInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    {!payByPhoneInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">PayByPhone Username</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{payByPhoneUsername}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">PayByPhone Password</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{payByPhonePassword}</div>
                                        </div>
                                    </>}
                                    {payByPhoneInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">PayByPhone Username</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={payByPhoneUsername} onChange={(e) => setPayByPhoneUsername(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">PayByPhone Password</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={payByPhonePassword} onChange={(e) => setPayByPhonePassword(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                                {payByPhoneInEdit && <>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setPayByPhoneInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>

                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default OrganisationSettings;