export const createGroupedChartTraces = (optionsArray, valuesByProperty) => {
    let barTraces = [];
    for (const [key, value] of Object.entries(valuesByProperty)) {
        const optionsSpread = optionsArray.reduce((acc, curr) => {
           acc[curr] = Number(value[curr]) ? Number(value[curr]) : 0;
           return acc;                      
        }, {})

        barTraces.push({
            "label": key,
            ...optionsSpread
        })
    }

    return barTraces;
}