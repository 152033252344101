import React, { useState, useEffect, useRef } from 'react';
import Api from '../api/api';
import Loader from '../shared/loader';
import { DataTable } from '../shared/dataTable';
import { paymentProviderLogoBasePath, PaymentProviderLogoEnum } from '../raw-data/payments-data';
import Pagination from '../shared/pagination';

const PaymentsDataEnum = {
    "logo": "",
    "vrm": "VRM",
    "similarity": "Similarity",
    "siteName": "Site",
    "start": "Start Date",
    "end": "End Date",
    "amount": "Amount"
}

export default function Payments(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [similarityThreshold, setSimilarityThreshold] = useState(65);
    const [dayRange, setDayRange] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [lastPage, setLastPage] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);

    const changeSimilarityThresholdTimeout = useRef();
    const changeSimilarityThreshold = function (x) {
        
        setSimilarityThreshold(Number(x));

        if (changeSimilarityThresholdTimeout.current) {
            clearTimeout(changeSimilarityThresholdTimeout.current);
            changeSimilarityThresholdTimeout.current = null;
        }

        changeSimilarityThresholdTimeout.current = setTimeout(() => {
            fetchPaymentsData(pageNumber, Number(x), dayRange);
        }, 1000);
    };

    const changeDayRangeTimeout = useRef();
    const changeDayRange = function (x) {

        setDayRange(Number(x));

        if (changeDayRangeTimeout.current) {
            clearTimeout(changeDayRangeTimeout.current);
            changeDayRangeTimeout.current = null;
        }

        changeDayRangeTimeout.current = setTimeout(() => {
            fetchPaymentsData(pageNumber, similarityThreshold, Number(x));
        }, 1000);
    };


    const fetchPaymentsData = (page, similarityThreshold, dayRange) => {
        setIsLoading(true);
        let breachDate = new Date(props.breachDate);
        let toDate = new Date(Date.UTC(breachDate.getFullYear(), breachDate.getMonth(), breachDate.getDate() + 1, 23, 59, 59));
        let fromDate = new Date(Date.UTC(breachDate.getFullYear(), breachDate.getMonth(), breachDate.getDate() - dayRange, 0, 0, 0));

        Api.getPaymentsData(
            data => { setData(data); setIsLoading(false) },
            () => setIsLoading(false),
            props.vrm, props.siteId, fromDate, toDate, parseInt(page), parseFloat(similarityThreshold / 100), props.organisationId, props.token
        )
    }
    useEffect(() => fetchPaymentsData(0, 65, 1), []);

    useEffect(() => {
        fetchPaymentsData(pageNumber, similarityThreshold, dayRange)
    }, [props.vrm, pageNumber, props.siteId]);

    const changepageNumber = (num) => {
        setPageNumber(num);
    };
    const isLastPage = () => {
        if (data && data !== undefined) {
            setLastPage(false)
            setPageCount(Math.ceil(data.totalCount / 100));
            if (pageNumber + 1 >= Math.ceil(data.totalCount / 100)) {
                setLastPage(true)
            }
        }
    };

    useEffect(() => isLastPage(), [data]);

    return <div className="card mb-3">
        <div className="card-header">
            <div className="row align-items-center px-md-3">
                    <div className="payments-accordion-header">
                        <h5 className="m-0">
                            <button className="button-no-style button-card-header collapsed" id="payments-accordion-button" type="button" data-toggle="collapse" data-target="#payments-list" aria-expanded="true" aria-controls="payments-list">
                                <p className='m-0'>Payments&nbsp;</p>
                                {
                                    isLoading
                                        ? <Loader classAddOn="p-0" />
                                        : <>
                                            { data &&
                                                <span className={ "translate-middle badge rounded-pill " + (data.totalCount > 0 ? "bg-success" : "bg-danger") }>
                                                    <>{ data.totalCount }</>
                                                </span>
                                            }
                                            &nbsp;<i className="fas fa-chevron-down"></i>
                                        </>

                                }
                            </button>
                        </h5>

                        <div className='payments-sliders'>
                            <div className='payments-slider'>
                                <label className='m-0'>Similarity: { similarityThreshold }%</label>
                                <input class="form-range" type="range" min="0" max="100" value={ similarityThreshold } onChange={ (e) => changeSimilarityThreshold(e.target.value) } />
                            </div>

                            <div className='payments-slider'>
                                <label className='m-0'>Previous { dayRange } day{ dayRange > 1 && <>s</> }</label>
                                <input class="form-range" type="range" min="1" max="14" value={ dayRange } onChange={ (e) => changeDayRange(e.target.value) } />
                            </div>
                        </div>
                        <div className="payments-refresh">
                                <button
                                    className="btn btn-primary btn-sm ml-3 nowrap"
                                    onClick={ () => fetchPaymentsData(pageNumber, similarityThreshold, dayRange) }>
                                    <i className="fas fa-redo"></i> Refresh
                                </button>
                        </div>
                    </div>
            </div>
        </div>
        <div className="card-body bg-light border-top details-container p-0 collapse" id="payments-list" aria-labelledby="Allow List" data-parent="#payments-accordion-button">
            { isLoading
                ? <Loader />
                : <>
                    {
                        data && data.items &&
                        <DataTable
                            enum={ PaymentsDataEnum }
                            tableData={
                                data.items
                                    .map(item => (
                                        {
                                            ...item,
                                            logo: `${ paymentProviderLogoBasePath }${ PaymentProviderLogoEnum[item.provider] }`,
                                        })
                                    )
                            }
                        />
                    }
                </>
            }
            <div className="d-flex justify-content-center row align-items-center pt-2">
                <div className="col-auto align-items-center">
                    <Pagination totalResults={ data?.totalCount } currentPage={ pageNumber } changePageNumber={ changepageNumber } />
                </div>
            </div>
        </div>
    </div>;
}