import { dataTableCellUtils } from '../../utilities/dataTableCellUtils';

export const DataTable = (props) => {
    return (
        <div className="card-body bg-white border-top details-container p-0 overflow-auto">
            <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                <thead className="bg-200 text-900">
                    <tr>
                        {Object.entries(props.enum).map(([key, value]) => <th className={key == "amount" ? "text-right pr-4" : ""}>{value}</th>)}
                    </tr>
                </thead>

                <tbody>
                    {
                        props.tableData?.map((item, index) =>
                            <tr key={index} className={item.errorDescription ? "table-danger" : ""}>
                                {
                                    Object.keys(props.enum)
                                    .map((key, index) => <td key={index}>{dataTableCellUtils(item, key)}</td>)
                                }
                            </tr>)
                    }
                </tbody>
            </table>
        </div>
    )
}