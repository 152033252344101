import Enums from './enum';
import moment from 'moment';
import { Link } from 'react-router-dom';
import stringHelpers from './stringHelpers';

export const dataTableCellUtils = (data, key) => {

    if (key === "breachStatus") return Enums.BreachStatus[data[key]]

    if (key === "vrm") {
        return <Link to={data.breachUrl} className="number-plate-link"><h6 className="mb-0 small-number-plate">{data[key]}</h6></Link>
    }

    if (key === "pcnReference" || key === "referenceNumber" || key === "breachReferenceNumber") {
        return <Link to={data.breachUrl}><h6 style={{color: "#5e6e82", margin: "0"}}>{data[key]}</h6></Link>
    }

    if (key === "siteName" && data.siteUrl) {
        return <Link to={data.siteUrl}><h6 style={{color: "#5e6e82", margin: "0"}}>{data[key]}</h6></Link>
    }

    if (key === "provider") {
        return Enums.PaymentType[data[key]];
    }

    if (key === "amount") {
        return <p className="text-right m-0" style={{paddingRight: "1.25rem"}}>{stringHelpers.formatDecimal(data[key], 2, "£")}</p>
    }

    if (key === "logo") {
        return <div style={{width: "100px", height: "60px"}} className="d-flex align-items-center"><img width="100%" src={data[key]} /></div>
    }

    if (key === "similarity") {
        let similarityClass = data[key] > 0.99 ? "bg-success" : (data[key] > 0.6 ? "bg-warning" : "bg-danger");

        return (
            <div class="progress" style={{minWidth: "100px"}}>
                <div 
                    class={`progress-bar text-left pl-1 ${similarityClass}`} 
                    role="progressbar" 
                    style={{width: `${data[key] * 100}%`}} 
                    aria-valuenow={data[key]} 
                    aria-valuemin="0" 
                    aria-valuemax="100"
                >{ stringHelpers.formatDecimal((data[key] * 100), 0) }%
                </div>
            </div>
        )
    }

    if (key === "fileName") {
        return <a target='_blank' href={data.attachmentUrl}><h6 style={{color: "#5e6e82", margin: "0"}}>{data[key]}</h6></a>
    }

    if (moment(data[key]).isValid()) return moment(data[key]).format("DD/MM/yyyy HH:mm")

    return data[key];
}