import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Api from '../api/api';
import { Link, useParams, useHistory } from "react-router-dom";
import { NewLetterEditTemplateItem } from './newLetterEditTemplateItem';
import Loader from '../shared/loader';

export default function NewLetter(props) {
    const { organisationId, breachId } = useParams();
    const history = useHistory();
    const [availableDocuments, setAvailableDocuments] = useState(null);
    const [contactDetailsList, setContactDetailsList] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [selectedContact, setSelectedContact] = useState("");
    const [templateItems, setTemplateItems] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSendingLetter, setIsSendingLetter] = useState(false);
    const [pdfView, setPdfView] = useState(null);

    const getDocuments = function () {
        Api.getLetterTemplates(
            (data) => setAvailableDocuments(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    const getContactDetails = function () {
        Api.getContactDetails(
            (data) => {
                if (data.length > 0) {
                    setContactDetailsList(data.filter(item => item.isActive));
                    setSelectedContact(data.find(item => item.isActive).id)
                }
            },
            (error) => console.log(error),
            breachId, props.organisationId, props.token
        )
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getDocuments();
        getContactDetails();
    }, [])

    const handleSendLetter = () => {
        setIsSendingLetter(true)
        Api.newLetter(
            organisationId, selectedTemplate, breachId, selectedContact, 1, templateItems,
            () => {
                setIsSendingLetter(false)
                history.replace(`/organisation/${ organisationId }/breach/${ breachId }`)
            },
            error => console.log(error),
            props.token
        )
    }

    useEffect(() => {
        if (selectedTemplate && selectedContact) {
            Api.getSelectedTemplateItems(
                organisationId, selectedTemplate, breachId, selectedContact,
                data => {
                    setTemplateItems(data);
                    refreshPdf(data, selectedTemplate);
                },
                error => console.log(error),
                props.token
            )
        } else {
            setTemplateItems(null);
            setPdfView(null);
        }
    }, [selectedTemplate, selectedContact])


    const handleValueUpdate = (value, item, index) => {
        const updatedTemplateItems = templateItems.toSpliced(index, 1, { ...item, content: value })
        setTemplateItems(updatedTemplateItems);
        refreshPdf(updatedTemplateItems, selectedTemplate);
    }

    const refreshPdf = (updatedTemplateItems, selectedTemplate) => {

        Api.newLetter(
            organisationId, selectedTemplate, breachId, selectedContact, 0, updatedTemplateItems,
            data => {
                setIsLoading(false);
                const blob = new window.Blob([data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                setPdfView(
                    <iframe
                        src={ fileURL }
                        style={ {
                            border: "0",
                            top: "0",
                            left: "0",
                            bottom: "0",
                            right: "0",
                            width: "100%",
                            height: "100%"
                        } }></iframe>
                )
            },
            error => {
                if (error.toString().indexOf('abort') === -1) {
                    setIsLoading(false);
                }
                setPdfView(<p>Failed to load preview.</p>)
            },
            props.token
        )
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-7">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto d-flex align-items-center pr-0">
                                    <h5 className="mb-0">New Letter</h5>
                                </div>
                                <div className="col-auto ml-auto text-right pl-0 filter-buttons-wrapper">
                                    <Link to={ `/organisation/${ organisationId }/breach/${ breachId }` } className="btn btn-falcon-default btn-sm"><i className="fas fa-reply mr-2"></i>Back</Link>
                                    <Button
                                        onClick={ () => setShowModal(true) }
                                        disabled={ !selectedTemplate || !selectedContact }
                                        className="btn btn-success btn-sm ml-2">
                                        <i className="fas fa-paper-plane mr-2"></i>Send Letter
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light border-top">
                            <div className="row mb-2">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Letter Template</label>
                                <div className="col-sm-8">
                                    <select
                                        class="border rounded py-1 px-2"
                                        onChange={ (e) => setSelectedTemplate(e.target.value) }
                                        value={ selectedTemplate ?? "" }
                                    >
                                        <option value={ "" }>Select a template</option>
                                        {
                                            availableDocuments && availableDocuments.length > 0 &&
                                            availableDocuments.filter(doc => doc.allowManualSend).map(item => <option key={ item.id } value={ item.id }>{ item.name }</option>)
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Contact</label>
                                <div className="col-sm-8">
                                    <select
                                        class="border rounded py-1 px-2"
                                        onChange={ (e) => setSelectedContact(e.target.value) }
                                        value={ selectedContact ?? "" }
                                    >
                                        <option value={ "" }>Select a contact</option>
                                        {
                                            contactDetailsList && contactDetailsList.length > 0 &&
                                            contactDetailsList.map(
                                                item => <option selected={ item.id === selectedContact } key={ item.id } value={ item.id }>{ item.firstName }&nbsp;{ item.lastName }</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>

                            {
                                selectedTemplate && templateItems && templateItems.length > 0 &&
                                templateItems.map((item, index) =>
                                    <div key={index} className="row mb-2">
                                        <NewLetterEditTemplateItem
                                            isSendingLetter={isSendingLetter}
                                            item={ item }
                                            handleValueUpdate={ value => handleValueUpdate(value, item, index) } />

                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className='col-5'>
                    {
                        isLoading || isSendingLetter
                            ? <Loader />
                            : <div id="preview-pdf" className="preview-pdf-container">
                                { pdfView }
                            </div>
                    }

                </div>
            </div>

            <Modal show={ showModal } onHide={ () => setShowModal(false) }>
                <Modal.Header>
                    <Modal.Title>
                        Send Letter
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="font-weight-semi-bold mt-1 mb-2">This letter will be printed and mailed immediately. Are you sure you want to continue?</p>
                    {
                        isLoading && <Loader />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-sm btn-danger mt-1"
                        onClick={ () => setShowModal(false) }
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-sm btn-success mt-1"
                        onClick={ () => {
                            setShowModal(false);
                            handleSendLetter()
                        } }
                    >
                        Confirm
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}