export default {

    emptyGuid: "00000000-0000-0000-0000-000000000000",

    cleanVrm(vrm) {
        const regex = new RegExp(/[^a-zA-Z0-9]/g);
        
        let result = vrm.replace(regex, "");
        result = result.toUpperCase();

        return result;
    },

    isNullOrEmpty(str) {
        return !str || str === null || str === "" || Array.isArray(str) || str.trim() === "";
    },

    anyAreNullOrEmpty(strings) {

        let result = false;
        strings.forEach(str => {
            if (this.isNullOrEmpty(str)) {
                result = true;
            }
        });
        return result;
    },

    isNullOrWhiteSpace(str) {
        return !str || str === null || str.trim().length === 0;
    },

    textSizeClass(amount) {

        if (!isNaN(amount)) {
            if (Math.abs(amount) >= 100000000) {
                return " text-xs";
            }
            if (Math.abs(amount) >= 10000000) {
                return " text-sm";
            }
            if (Math.abs(amount) >= 1000000) {
                return " text-md";
            }
            if (Math.abs(amount) >= 100000) {
                return " text-lg";
            }
        }
        return "";
    },

    toInitials(nameParts) {
        if (nameParts && nameParts !== null && nameParts.length > 0) {
            let result = "";

            for (let i = 0; i < nameParts.length; i++)
            {
                if (!this.isNullOrWhiteSpace(nameParts[i])) {
                    result += nameParts[i].substr(0, 1).toUpperCase();
                }
            }
            
            return result.substr(0, Math.min(2, result.length));
        }

        return "";
    },

    photoFormat(contentType) {
        if (!this.isNullOrWhiteSpace(contentType)) {
            switch (contentType.toLowerCase()) {
                case "image/bmp":
                    return "bmp";
                case "image/gif":
                    return "gif";
                case "image/jpeg":
                    return "jpg";
                case "image/png":
                    return "png";
            }
        }

        return null;
    },
    
    formatDecimal(number, decimalPlaces, currencySymbol, showPositivePrefix) {

        if (!isNaN(number)) {
    
            let isNegative = number < 0;
            let unformatted = "" + Math.abs(number).toFixed(decimalPlaces);
            let commaFormatted = "";
    
            let indexIterator = unformatted.indexOf(".");
            if (indexIterator < 0) {
                indexIterator = unformatted.length;
            }
    
            for (let i = indexIterator - 1; i >= 0; i--) {
                commaFormatted = unformatted.substr(i, 1) + commaFormatted;
                if (i > 0 && commaFormatted.replace(/,/g, "").length % 3 === 0) {
                    commaFormatted = "," + commaFormatted;
                }
            }
    
            if (currencySymbol) {
                commaFormatted = currencySymbol + commaFormatted;
            }
    
            if (isNegative) {
                commaFormatted = "-" + commaFormatted;
            } else if (showPositivePrefix) {
                commaFormatted = "+" + commaFormatted;
            }
    
            return commaFormatted + (unformatted.indexOf(".") > -1 ? unformatted.substring(unformatted.indexOf(".")) : "");
        } else {
            let result = 0;
            return result.toFixed(decimalPlaces);
        }
    },

    checkSearchQueryMatch(searchQuery, referenceString) {
        return referenceString.toLowerCase().includes(searchQuery.toLowerCase());
    },

    formatHoursMinutes(numberOfMinutes) {
        if (numberOfMinutes === 1440) {
            return "23:59:59"
        }
        const hours = Math.floor(numberOfMinutes / 60);
        const minutes = numberOfMinutes - (hours * 60);
        return `${hours < 10 ? '0' : ''}${hours}:${minutes}${minutes === 0 ? '0' : ''}`
    },

    formatTimeStringToMinutes(timeString) {
        const timeArray = timeString.split(":");
        return timeString === "23:59:59" ? 1440 : (Number((timeArray[0] * 60)) + Number(timeArray[1]))
    }
};