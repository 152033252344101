import { useEffect } from 'react';
import { useState } from 'react';
import ReactSlider from 'react-slider';
import stringHelpers from '../../utilities/stringHelpers';

export const MultiValueRangeSlider = ({ label, handleChange, maxValue, minValue, helperText }) => {

    const [valueLeftThumb, setValueLeftThumb] = useState(-minValue);
    const [valueRightThumb, setValueRightThumb] = useState(maxValue);
    const onChange = (value) => {
        if (value[0] > minValue && value[0] > 0) {
            setValueLeftThumb(0)
        } else if (value[1] < maxValue && value[1] < 0) {
            setValueRightThumb(0)
        } else {
            setValueLeftThumb(value[0])
            setValueRightThumb(value[1])
        }
    }

    useEffect(() => handleChange([valueLeftThumb, valueRightThumb]), [valueLeftThumb, valueRightThumb])

    return (
        <div className="row">
            <div className="col-md-3 col-12">
                <p className="font-weight-semi-bold mb-1">{ label }</p>
            </div>
            <div className="form-group col-md-5 col-12 d-flex flex-column align-items-center">

                <div className='w-100 mt-3'>
                <ReactSlider
                    className='range-slider'
                    thumbClassName='range-slider-handle'
                    trackClassName='range-slider-track'
                    min={ -720 }
                    max={ 1440 }
                    value={ [valueLeftThumb, valueRightThumb] }
                    ariaLabel={ ['Lower handle', 'Upper handle'] }
                    ariaValuetext={ state => `Handle value ${ state.valueNow }` }
                    step={ 15 }
                    renderThumb={ (props, state) => {
                        const formattedDuration = state.valueNow === 1440 ? "+24:00" : `${state.valueNow < 0 ? "-" : state.valueNow > 0 ? "+" : ""}${stringHelpers.formatHoursMinutes(Math.abs(state.valueNow))}`
                        return (
                        <div { ...props }>
                            <div className='range-slider-tooltip'>{ formattedDuration }</div>
                        </div>)
                    } }
                    pearling
                    minDistance={ 0 }
                    onChange={ onChange }
                />
                </div>
                <div className='mt-3'>
                { helperText }
                </div>
            </div>
        </div>
    )
}