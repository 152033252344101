import { useEffect, useState } from "react";
import Api from "../api/api";
import Loader from "../shared/loader";
import { DataTable } from "../shared/dataTable";
import { DatePicker } from "../shared/datePicker";
import Pagination from "../shared/pagination";
import moment from "moment";

const LettersDataEnum = {
    "breachReferenceNumber": "Reference Number",
    "date": "Date",
    "fileName": "Attachment",
    "iMailStatus": "iMail Status",
}

export default function LettersRawData(props) {
    const [lettersData, setLettersData] = useState(null);
    const [lettersSearch, setLettersSearch] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [lastPage, setLastPage] = useState(false);

    const getLettersData = (searchQuery, from, to, pageNumber) => {
        setIsLoading(true);
        Api.getLettersRawData(
            data => {
                setLettersData(data);
                setIsLoading(false);
            },
            () => {
                setLettersData(null);
                setIsLoading(false);
            },
            searchQuery,
            from, to, pageNumber, props.organisationId, props.token
        )
    }

    const changepageNumber = (num) => {
        getLettersData(lettersSearch, fromDate, toDate, num)
        setPageNumber(num);
    };
    const isLastPage = () => {
        if (lettersData && lettersData !== undefined) {
            setLastPage(false)
            setPageCount(Math.ceil(lettersData.totalCount / 100));
            if (pageNumber + 1 >= Math.ceil(lettersData.totalCount / 100)) {
                setLastPage(true)
            }
        }
    };
    useEffect(() => getLettersData("", null, null, 0), [])
    useEffect(() => {
        getLettersData(lettersSearch, fromDate, toDate, 0);
        setPageNumber(0)
    }, [fromDate, toDate, lettersSearch]);
    useEffect(() => isLastPage(), [lettersData]);

    const clearFilters = () => {
        setFromDate(null);
        setToDate(null);
        setLettersSearch("");
    }

    const handleRefresh = () => {
        clearFilters();
        getLettersData("", null, null, 0);
        setPageNumber(0);
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-header raw-data-card-header d-flex align-items-center">
                            <h5 className="mb-0">
                                Letters
                            </h5>
                            <div className="raw-data-filters-container">
                                <input
                                    className="form-control kadoe-search-bar"
                                    value={ lettersSearch }
                                    type="text"
                                    autoFocus={ true }
                                    placeholder="search..."
                                    onChange={ e => setLettersSearch(e.target.value) }
                                />
                                <button
                                    className="btn btn-falcon-default btn-sm py-0 d-flex align-items-center"
                                    onClick={ clearFilters }><i className="fas fa-times mr-2"></i> Clear
                                </button>
                                <div className="date-pickers">
                                    <DatePicker title="From" date={ fromDate } handleChange={ date => setFromDate(new Date(date)) } />
                                    <DatePicker title="To" date={ toDate } handleChange={ date => setToDate(new Date(date)) } />
                                </div>
                                <button
                                    className="btn btn-primary btn-sm mr-1 ml-1 nowrap"
                                    onClick={ handleRefresh }>
                                    <i className="fas fa-redo"></i> Refresh
                                </button>
                            </div>
                        </div>
                        { isLoading
                            ? <Loader />
                            : <DataTable
                                enum={ LettersDataEnum }
                                tableData={
                                    lettersData?.items?.map(
                                        element => ({
                                            ...element,
                                            breachUrl:`/organisation/${props.organisationId}/breach/${element.breachId}`,
                                            attachmentUrl: `${element.fileUrl}&ts=${moment().milliseconds()}`
                                        })
                                    )
                                } />
                        }
                        <div className="d-flex justify-content-center row align-items-center pt-2">
                            <div className="col-auto align-items-center">
                                <Pagination totalResults={lettersData?.totalCount} currentPage={pageNumber} changePageNumber={changepageNumber} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}