import enums from "../utilities/enum";

export default function RoleSwitch(props) {
    const roleId = props.user !== null && props.user.isGlobalAdmin ? 0 : props.user.organisations.find(item => item.organisation.id === props.organisationId)?.roleId;

    return <div class="container" data-layout="container">
        <div className="row flex-center min-vh-75 py-6">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                <a className="d-flex flex-center mb-5" href="/">
                    <img src="/assets/img/apt-logos/logo.svg" alt="" width="150" />
                </a>
                <div className="card">
                    <div className="card-header bg-light">
                        <h5 className="mb-0">Select a role</h5>
                    </div>
                    <div className="card-body py-4 px-sm-2">
                        <div className="d-flex flex-column">
                            <a className="btn btn-link btn-block d-flex justify-content-between align-items-center" href="/">
                                <span>{ enums.UserRoles[roleId] }</span>
                                <span className="fas fa-chevron-right fs--2 ml-1"></span>
                            </a>
                            <a className="btn btn-link btn-block d-flex justify-content-between align-items-center" href="/warden">
                                <span>Warden</span>
                                <span className="fas fa-chevron-right fs--2 ml-1"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}