import { LineChart, Line, Legend, ResponsiveContainer, Bar, BarChart, XAxis, YAxis, Tooltip } from 'recharts'
const barChartColours = ["#fcd206", "#ff7e34", "#5B7999", "#9da34e", "#8DA7BB", "#FF33FF", "#FFF800", "#B7CCD8"]

export const ReportsCharts = ({ data, chartStyle, showLegend }) => {

    const CustomizedXAxisTick = ({ x, y, payload }) => {
        const deltaX = payload.value.length > 15 ? 40 : 24;
        const shortenedText = payload.value.length > 15 ? `${ payload.value.substring(0, 15) }...` : payload.value;
        return (
            <g transform={ `translate(${ x },${ y }) rotate(45)` }>
                <text style={ { fontSize: "10px" } } x={ 0 } y={ 0 } dx={0} dy={ 8 } textAnchor="start" fill="#666">{ shortenedText }</text>
            </g>
        )
    };

    const commonChartElements = (
        <>
            <XAxis height={100} dataKey="label" interval={ 0 } tick={ <CustomizedXAxisTick /> } />
            <YAxis />
            { showLegend && <Legend verticalAlign="top" align="center" height={ 36 } /> }
            <Tooltip />
        </>
    )
      
    return (
        <ResponsiveContainer width="100%" height={400}>
            {
                chartStyle === "bar"
                    ? <BarChart data={ data }>
                        {commonChartElements}
                        {
                            Object.keys(data[0]).map((item, index) => {
                                if (item !== "label") return <Bar name={ item } dataKey={ item } fill={ barChartColours[index] } />
                            })
                        }
                    </BarChart>
                    : <LineChart data={ data } height={300}>
                        {commonChartElements}
                        {
                            Object.keys(data[0]).map((item, index) => {
                                if (item !== "label") return <Line name={ item } dataKey={ item } fill={ barChartColours[index] } stroke={ barChartColours[index] } />
                            })
                        }
                    </LineChart>
            }
        </ResponsiveContainer>
    )
}